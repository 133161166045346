import { Observable, from } from 'rxjs';

import { AppTypeEnum } from '../model/appType';
import { DexieService } from './dexieService';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { User } from '../model/user';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class UsersService {

    constructor(
        private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) {
    }

    findAllFromGroupAppType(idAppType: number): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + `/users/appType/${idAppType}`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.responsablesGroup.toArray().timeout(30000));
        }
    }

    findAllRespFromGroup(): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + '/users/responsables';

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.responsables.toArray().timeout(30000));
        }
    }

    findAllUserToSignFromGroup(): Observable<any> {
        const url = environment.coreUrl + '/users/usersToSign/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    saveRespToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAllRespFromGroup().subscribe((res: User[]) => {
            if (res) {
                void this.dexieService.responsables.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.responsables.put(type).then(() => resolve());
                    });
                });
            }
        }));
    }

    saveRespFromGroupToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAllFromGroupAppType(AppTypeEnum.AIR).subscribe((res: User[]) => {
            if (res) {
                void this.dexieService.responsablesGroup.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.responsablesGroup.put(type).then(() => resolve());
                    });
                });
            }
        }));
    }
}
