import { Group } from '../model/group';

export class GroupUtils {

    static objectToGroup(item: Group | any): Group {

        if (item instanceof Group) {
            return item;
        } else {
            const s = new Group();

            for (const prop in item) {
                if (prop !== 'data') {
                    s[prop] = item[prop];
                }
            }

            return s;
        }
    }
}


export enum GroupEnum {
    QUALIPHARMA = 1,
}