/* eslint-disable max-len */
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AccessoryEquipmentTypeService } from './services/accessoryEquipmentType.service';
import { AccessoryFilterTypeService } from './services/accessoryFilterType.service';
import { AngularMaterialModule } from './external-components/angular-material.module';
import { AppComponent } from './app.component';
import { AppPlanEditComponent } from './components/admin/app-plan-edit/app-plan-edit.component';
import { AppPlanListComponent } from './components/admin/app-plan-list/app-plan-list.component';
import { AppPlanTypeService } from './services/appPlanType.service';
import { AppRoutingModule } from './app-routing.module';
import { AppTypeService } from './services/appType.service';
import { AreaService } from './services/areaService';
import { AttachmentAirService } from './services/attachmentAir.service';
import { AttachmentCalibratesService } from './services/attachmentCalibrates.service';
import { AttachmentThermalService } from './services/attachmentThermal.service';
import { BioindicatorResponsibleService } from './services/bioindicatorResponsible.service';
import { BroadcastChannelService } from './services/broadcastChannel.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditor5Module } from './external-components/ckeditor.module';
import { CalibrationEditModule } from './components/calibrations/calibration-edit/calibration-edit.module';
import { CalibrationListModule } from './components/calibrations/calibration-list/calibration-list.module';
import { CalibrationPlaceService } from './services/calibrationPlace.service';
import { CalibrationPlanEditComponent } from './components/admin/calibration-plan-edit/calibration-plan-edit.component';
import {
  CalibrationPlanEditCustomFieldComponent
} from './components/admin/calibration-plan-edit/calibration-plan-edit-custom-field.component';
import { CalibrationPlanEditFolderComponent } from './components/admin/calibration-plan-edit/calibration-plan-edit-folder.component';
import { CalibrationResponsibleService } from './services/calibrationResponsible.service';
import { CalibrationService } from './services/calibration.service';
import { CalibrationStatusService } from './services/calibrationStatus.service';
import { ChangePasswordModule } from './components/shared/change-password/change-password.module';
import { ClientEditModule } from './components/clients/client-edit/client-edit.module';
import { ClientListModule } from './components/clients/client-list/client-list.module';
import { ClientService } from './services/client.service';
import { CompressedGasService } from './services/compressedGasService';
import { CompressedGasesTypeService } from './services/compressedGasesType.service';
import { CookieModule } from 'ngx-cookie';
import { CookieService } from 'ngx-cookie-service';
import { CountryService } from './services/country.service';
import { CriteriaTypeService } from './services/criteriaType.service';
import { DatabaseService } from './services/database.service';
import { DefrostService } from './services/defrost.service';
import { DefrostTypeService } from './services/defrostType.service';
import { DevextremeModule } from './external-components/devextreme.module';
import { DexieService } from './services/dexieService';
import { DrawerService } from './services/drawer.service';
import { EditUserModule } from './components/admin/edit-user/edit-user.module';
import { EquipmentTypeAirService } from './services/equipmentTypeAir.service';
import { EquipmentTypeService } from './services/equipmentType.service';
import { ErrorModule } from './components/shared/error/error.module';
import { EssayAirService } from './services/essayAir.service';
import { EssayConfigAirService } from './services/essayConfigAir.service';
import { EssayService } from './services/essay.service';
import { ExecutionAirEditModule } from './components/air/execution-edit/execution-edit.module';
import { ExecutionAirListModule } from './components/air/execution-list/execution-list.module';
import { ExecutionAirService } from './services/executionAir.service';
import { ExecutionEditModule } from './components/thermal/execution-edit/execution-edit.module';
import { ExecutionEquipmentService } from './services/executionEquipmentService';
import { ExecutionListModule } from './components/thermal/execution-list/execution-list.module';
import { ExecutionService } from './services/execution.service';
import { ExecutionStatusService } from './services/executionStatus.service';
import { ExternalCalibrationService } from './services/externalCalibration.service';
import { FilterAirTypeService } from './services/filterAirType.service';
import { FilterService } from './services/filterService';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ForgottenPasswordComponent } from './components/shared/forgotten-password/forgotten-password.component';
import { GroupEditModule } from './components/admin/group-edit/group-edit.module';
import { GroupsListModule } from './components/admin/groups-list/groups-list.module';
import { HomeComponent } from './components/home/home.component';
import { InstrumentationService } from './services/instrumentation.service';
import { InterceptorsModule } from './interceptors/interceptors.module';
import { InternalEquipmentCustomFieldsService } from './services/internalEquipmentCustomFields.service';
import { InternalEquipmentEditModule } from './components/internal-equipments/internal-equipment-edit/internal-equipment-edit.module';
import { InternalEquipmentFolderService } from './services/internalEquipmentFolderService';
import { InternalEquipmentListModule } from './components/internal-equipments/internal-equipment-list/internal-equipment-list.module';
import { InternalEquipmentService } from './services/internalEquipment.service';
import { InternalEquipmentStatusService } from './services/internalEquipmentStatus.service';
import {
  IsothermalCharacterizationEditModule
} from './components/calibrations/isothermal-characterization-edit/isothermal-characterization-edit.module';
import {
  IsothermalCharacterizationListModule
} from './components/calibrations/isothermal-characterization-list/isothermal-characterization-list.module';
import { IsothermalCharacterizationService } from './services/isothermalCharacterization.service';
import { IsothermalCharacterizationStatusService } from './services/isothermalCharacterizationStatus.service';
import { JosetAirService } from './services/josetAirService.service';
import { LoginComponent } from './components/login/login.component';
import { LoginService } from './services/login.service';
import { MaintenanceModeComponent } from './components/shared/maintenance-mode/maintenance-mode.component';
import { MaintenanceModeService } from './services/maintenanceMode.service';
import { ManageGroupsService } from './services/manageGroups.service';
import { ManageNotificationsService } from './services/manageNotifications.service';
import { ManageUsersModule } from './components/admin/manage-users/manage-users.module';
import { ManageUsersService } from './services/manageUsers.service';
import { MaterialElevationDirective } from './directives/material-elevation.directive';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotificationEditComponent } from './components/admin/notification-edit/notification-edit.component';
import { NotificationListComponent } from './components/admin/notification-list/notification-list.component';
import { NotificationService } from './services/notification.service';
import { NotificationsModule } from './components/notifications/notifications.module';
import { OnlineService } from './services/online.service';
import { PaymentsModule } from './components/payments/payments.module';
import { PaymentsService } from './services/payments.service';
import { PeakTypeService } from './services/peakType.service';
import { PendingSignStatusService } from './services/pendingSignStatus.service';
import { PendingSignTypeService } from './services/pendingSignType.service';
import { PhaseService } from './services/phase.service';
import { PipesModule } from './pipes/pipes.module';
import { ProcedureService } from './services/procedure.service';
import { ProcessService } from './services/process.service';
import { ProfileModule } from './components/shared/profile/profile.module';
import { ProtocolAirEditModule } from './components/air/protocol-edit/protocol-edit.module';
import { ProtocolAirListModule } from './components/air/protocol-list/protocol-list.module';
import { ProtocolAirService } from './services/protocolAir.service';
import { ProtocolEditModule } from './components/thermal/protocol-edit/protocol-edit.module';
import { ProtocolListModule } from './components/thermal/protocol-list/protocol-list.module';
import { ProtocolService } from './services/protocol.service';
import { ProtocolStatusService } from './services/protocolStatus.service';
import { RequestCacheService } from './services/requestCache.service';
import { RestorePasswordComponent } from './components/shared/restore-password/restore-password.component';
import { RoomService } from './services/roomService';
import { RoomSubtypeAirService } from './services/roomSubtypeAir.service';
import { RoomTypeAirService } from './services/roomTypeAir.service';
import { SaveExecutionOfflineService } from './services/saveExecutionOffline.service';
import { SendNotificationsComponent } from './components/admin/send-notifications/send-notifications.component';
import { SharedModule } from './components/shared/shared.module';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { SnackBarService } from './services/snackBar.service';
import { SpinnerService } from './services/spinner.service';
import { ThemeService } from './services/theme.service';
import { ThermalModule } from './components/thermal/thermal.module';
import { ToastrModule } from 'ngx-toastr';
import { TranslateUniversalLoader } from './utils/translateLoader';
import { UserService } from './services/user.service';
import { UsersService } from './services/users.service';
import { VariableTypeService } from './services/variableType.service';
import { VersionCheckService } from './services/version-check.service';
import { VolumeService } from './services/volumeService';
import { PrincipalComponent } from './components/principal/principal.component';
import { ClientContatDialogContactPersonComponent } from './components/clients/client-edit/client-contat-dialog-contact-person/client-contat-dialog-contact-person.component';
import { LoginClientsComponent } from './components/customer-portal/customer-portal-login/login-clients.component';
import { RestorePasswordClientComponent } from './components/customer-portal/customer-portal-restore-password/restore-password-client.component';
import { ForgetPasswordClientComponent } from './components/customer-portal/customer-portal-forget-password-client/forget-password-client.component';
import { CustomerPortalGenericDocumentListComponent } from './components/customer-portal/customer-portal-generic-document-list/customer-portal-generic-document-list.component';
import { CustomerPortalGenericDocumentListFilterComponent } from './components/customer-portal/customer-portal-generic-document-list/customer-portal-generic-document-list-filter/customer-portal-generic-document-list-filter.component';
import { HomeAirComponent } from './components/home/home-air/home-air.component';
import { GlobalStateService } from './services/globalState.service';
import { HomeThermalComponent } from './components/home/home-thermal/home-thermal.component';
import { HomeCalibratesComponent } from './components/home/home-calibrates/home-calibrates.component';
import { ProcedureEditDialogComponent } from './components/calibrations/procedures/procedure-edit/procedure-edit-dialog.component';
import { ProtocolQService } from './services/protocolQ.service';
import { HomeQualificatesComponent } from './components/home/home-qualificates/home-qualificates.component';
import { ProtocolQEditModule } from './components/qualificates/protocol-edit/protocol-edit.module';
import { ReferenceDocQProtocolModule } from './components/qualificates/referenceDocProtocol-list/referenceDocProtocol-list.module';
import { EquipmentTypeQualificatesService } from './services/equipmentTypeQualificates.service';

@NgModule({
  declarations: [
    AppComponent, LoginComponent, SidebarComponent, HomeComponent, ForgottenPasswordComponent, RestorePasswordComponent,
    AppPlanListComponent, AppPlanEditComponent, MaterialElevationDirective, MaintenanceModeComponent, NotificationListComponent,
    NotificationEditComponent, SendNotificationsComponent, CalibrationPlanEditComponent, CalibrationPlanEditFolderComponent,
    CalibrationPlanEditCustomFieldComponent,
    PrincipalComponent,
    ClientContatDialogContactPersonComponent,
    LoginClientsComponent,
    RestorePasswordClientComponent,
    ForgetPasswordClientComponent,
    CustomerPortalGenericDocumentListComponent,
    CustomerPortalGenericDocumentListFilterComponent,
    HomeAirComponent,
    HomeThermalComponent,
    HomeCalibratesComponent,
    ProcedureEditDialogComponent,
    HomeQualificatesComponent
  ],
  imports: [
    /* Angular */
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,

    /* Translate */
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),

    /* Cookies */
    CookieModule.forRoot(),

    /* Custom modules */
    AngularMaterialModule, DevextremeModule, CKEditor5Module, InterceptorsModule, PipesModule, ProfileModule, NotificationsModule,
    ProtocolListModule, ProtocolEditModule, ExecutionListModule, ExecutionEditModule, InternalEquipmentListModule,
    InternalEquipmentEditModule, ManageUsersModule, EditUserModule, SharedModule, GroupsListModule, GroupEditModule, ChangePasswordModule,
    ClientListModule, ClientEditModule, CalibrationListModule, CalibrationEditModule, IsothermalCharacterizationListModule,
    IsothermalCharacterizationEditModule, PaymentsModule, ErrorModule, ThermalModule, ProtocolAirListModule,
    ProtocolAirEditModule, ExecutionAirListModule, ExecutionAirEditModule, ProtocolQEditModule, ReferenceDocQProtocolModule,

    /* Otros */
    FlexLayoutModule,

    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      extendedTimeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
      closeButton: true
    }),

    NgxSpinnerModule
  ],
  providers: [
    /* Servicios */
    LoginService, DrawerService, ProtocolService, PhaseService, ClientService, InstrumentationService, ProtocolStatusService,
    ProcessService, UserService, UsersService, ManageUsersService, ManageGroupsService, EssayService, ExecutionService,
    ExecutionStatusService, AttachmentThermalService, AttachmentCalibratesService, NotificationService, InternalEquipmentService,
    InternalEquipmentStatusService, OnlineService, RequestCacheService, DatabaseService, VersionCheckService, CriteriaTypeService,
    DefrostService, DefrostTypeService, BioindicatorResponsibleService, PeakTypeService, AppPlanTypeService, VariableTypeService,
    ThemeService, AppTypeService, CalibrationResponsibleService, CalibrationService, CalibrationStatusService, ProcedureService,
    CalibrationPlaceService, ExternalCalibrationService, IsothermalCharacterizationService, IsothermalCharacterizationStatusService,
    PaymentsService, CountryService, PendingSignTypeService, PendingSignStatusService, SnackBarService, MaintenanceModeService,
    EquipmentTypeService, ProtocolAirService, EquipmentTypeAirService, EssayAirService, EssayConfigAirService, RoomTypeAirService,
    SpinnerService, FilterAirTypeService, AccessoryFilterTypeService, AccessoryEquipmentTypeService, ExecutionAirService,
    AttachmentAirService, ManageNotificationsService, JosetAirService, RoomSubtypeAirService, DexieService, SaveExecutionOfflineService,
    AreaService, RoomService, ExecutionEquipmentService, VolumeService, FilterService, CompressedGasesTypeService, CompressedGasService,
    InternalEquipmentFolderService, InternalEquipmentCustomFieldsService, BroadcastChannelService, ProtocolQService, EquipmentTypeQualificatesService,

    /* Servicios externos */
    NgxImageCompressService,
    CookieService,

    GlobalStateService,

    /* https://stackoverflow.com/questions/35284988/angular-2-404-error-occur-when-i-refresh-through-the-browser */
    { provide: LocationStrategy, useClass: HashLocationStrategy },

    // https://stackoverflow.com/a/60275759/3693956
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient): TranslateLoader {
  return new TranslateUniversalLoader(http, 'assets/i18n/', '.properties');
}
