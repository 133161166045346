import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AppTypeEnum } from 'src/app/model/appType';
import { Client } from 'src/app/model/client';
import { GenericClass } from 'src/app/model/genericClass';
import { Phase } from 'src/app/model/phase';
import { ProtocolFilter, ProtocolQFilter, ProtocolStatus } from 'src/app/model/protocol';
import { User } from 'src/app/model/user';
import { ClientService } from 'src/app/services/client.service';
import { PhaseService } from 'src/app/services/phase.service';
import { ProcessService } from 'src/app/services/process.service';
import { ProtocolStatusService } from 'src/app/services/protocolStatus.service';
import { UsersService } from 'src/app/services/users.service';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';

@Component({
  selector: 'app-protocol-list-filter',
  templateUrl: './protocol-list-filter.component.html'
})
export class ProtocolListFilterComponent implements OnInit {

  @Output() filterEmitter = new EventEmitter<ProtocolQFilter>();
  
  filter: ProtocolQFilter;
  form: FormGroup;
  isList: boolean;
  clients: Client[];
  clientsFiltered: Client[];
  private destroy$ = new Subject<void>();
  expandedFilter = true;
  statuses: GenericClass[];
  processes: GenericClass[];
  projectManagers: User[];
  users: User[];
  customDatepickerHeader = CustomDatepickerHeaderComponent;

  constructor(
    fb: FormBuilder,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private protocolStatusService: ProtocolStatusService,
    private processService: ProcessService,
    private usersService: UsersService) {
    this.cleanFilter();

    this.form = fb.group({
      idClient: [this.filter.idClient],
      idProcess: [this.filter.idProcess],
      projectNo: [this.filter.projectNo],
      documentCode: [this.filter.documentCode],
      clientDocumentCode: [this.filter.clientDocumentCode],

      regUser: [this.filter.regUser],
      projectManager: [this.filter.projectManager],

      regFcStart: [this.filter.regFcStart],
      modFcStart: [this.filter.modFcStart],
      regFcEnd: [this.filter.regFcEnd],
      modFcEnd: [this.filter.modFcEnd],

      idStatus: [this.filter.idStatus],

      realizationFcStart: [this.filter.realizationFcStart],
      realizationFcEnd: [this.filter.realizationFcEnd],
      
      equipment: [this.filter.equipment],
      equipmentMaker: [this.filter.equipmentMaker],
      equipmentModel: [this.filter.equipmentModel],
      equipmentLocation: [this.filter.equipmentLocation],
      equipmentSerialNum: [this.filter.equipmentSerialNum],
      equipmentInternalId:[this.filter.equipmentInternalId]
    });
  }

  ngOnInit(): void {
    this.clients = [];
    this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => {
      const empty = new Client();
      empty.id = null;
      empty.name = '---';

      data = [empty].concat(data);

      this.clients = data;
      this.clientsFiltered = this.clients.slice();
    });
    this.statuses = [];
    this.protocolStatusService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.statuses = data);

    this.processes = [];
    this.processService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.processes = data);
    
    this.projectManagers = [];
    this.usersService.findAllRespFromGroup().pipe(takeUntil(this.destroy$)).subscribe((data: User[]) => this.projectManagers = data);    
  }

  cleanFilter(): void {
    this.filter = new ProtocolQFilter();

    if (this.isList === false) {
      this.filter.idStatus = ProtocolStatus.FIRMADO;
    }
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  saveDate(field: string, event: MatDatepickerInputEvent<Date>): void {

    let dateValue = event.value;
    if (dateValue != null) {   
      this.filter[field] = new Date(dateValue);
    } else {
      this.filter[field] = null;
    }
  }
}
