import { Client, ClientFilter } from '../model/client';
import { Observable, defer, from, of } from 'rxjs';

import { DexieService } from './dexieService';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ClientService {

    constructor(private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) {
    }

    findAll(): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.qualificatesUrl + '/client/';

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.clients.toArray().timeout(30000));
        }
    }

    findAllShort(): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.thermalUrl + '/client/list';

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.clients.toArray().timeout(30000));
        }
    }

    filter(filter: ClientFilter): Observable<any> {
        const url = environment.thermalUrl + '/client/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getExcel(filter: ClientFilter): Observable<any> {
        const url = environment.thermalUrl + '/client/excel';

        return this.http.post(url, filter, { responseType: 'blob' });
    }

    findOne(id: number): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.thermalUrl + `/client/${id}`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return defer(() => from(this.dexieService.clients.where({ id }).toArray().timeout(10000)));
        }
    }

    save(client: Client): Observable<any> {
        let url = environment.thermalUrl + '/client/';

        if (client.id != null) {
            url += client.id;

            return this.http.put(url, client).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            url += 'create';

            return this.http.post(url, client).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        }
    }

    uploadMassive(data: Client[]): Observable<any> {
        const url = environment.thermalUrl + '/client/create/massive';

        return this.http.post(url, data).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    activate(id: number, activate: boolean): Observable<any> {
        return this.findOne(id).pipe(map(item => {
            const client = item as Client;
            client.active = activate;
            return this.save(client);
        }, err => {
            return of(err);
        }));
    }

    downloadAuditPdf(id: number): Observable<any> {
        const url = environment.thermalUrl + `/report/client/${id}/audit`;

        return this.http.get(url, { responseType: 'blob' });
    }

    saveClientToDexie(id: number): Promise<void> {
        return new Promise<void>((resolve) => this.findOne(id).subscribe((res: Client) => {
            void this.dexieService.clients.put(res).then(() => resolve());
        }));
    }
}
