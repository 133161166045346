import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProtocolQ, ProtocolStatus } from 'src/app/model/protocol';
import { User } from 'src/app/model/user';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { GenericClass } from 'src/app/model/genericClass';
import { Client } from 'src/app/model/client';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/utils/constants';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProtocolQService } from 'src/app/services/protocolQ.service';
import { ClientService } from 'src/app/services/client.service';
import { UsersService } from 'src/app/services/users.service';
import { ProcessService } from 'src/app/services/process.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogDataConfirmSave, ProtocolEditConfirmSaveComponent } from './protocol-edit-confirm-save/protocol-edit-confirm-save.component';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { ProtocolQEditAuditComponent } from './protocol-q-edit-audit/protocol-q-edit-audit.component';
import { TestQualificationTypeService } from '../../../services/testQualificationType.service';
import { TestQualificationTypeShort } from '../../../model/testQualificationType';
import { TestQType } from '../../../model/testQType';
import { GroupTestQDataVerification } from 'src/app/model/groupTestQDataVerification';
import { ProtocolQTest, ProtocolQGeneralData } from '../../../model/protocol';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTable } from '@angular/material/table';
import { EditReferenceDocsComponent } from '../../shared/edit-reference-docs/edit-reference-docs.component';
import * as _ from 'lodash';
import { ReferenceDocument } from 'src/app/model/referenceDocument';
import { ProtocolEditEquipmentDialogComponent } from './protocol-edit-equipment-dialog/protocol-edit-equipment-dialog.component';
import { EquipmentQualificates } from 'src/app/model/equipment';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ProtocolQEditTestComponent } from './protocol-q-edit-test/protocol-q-edit-test.component';
import { ProtocolQTestsService } from '../../../services/protocol-qtests.service';
import { ProtocolQEditSectionTemplateComponent } from './protocol-q-edit-section-template/protocol-q-edit-section-template.component';
import { ProtocolQEditGenerateReportComponent } from './protocol-q-edit-generate-report/protocol-q-edit-generate-report.component';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';

@Component({
  selector: 'app-protocol-edit',
  templateUrl: './protocol-edit.component.html'
})
export class ProtocolQEditComponent implements OnInit {

  @ViewChild(ProtocolQEditAuditComponent) auditTab: ProtocolQEditAuditComponent; // Crear Audit propio
  @ViewChild('refDocTable', { static: true }) refDocTable: MatTable<any>;

  form: FormGroup;
  matcher = new MyErrorStateMatcher();
  protocol: ProtocolQ;

  clients: Client[];
  clientsFiltered: Client[];
  processes: GenericClass[];
  respField: User[];
  groupSelected: GroupTestQDataVerification[] = [];
  testQualificationTypeList: TestQualificationTypeShort[] = [];

  private destroy$ = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  displayedColsRefDoc = ['code', 'name', 'edit', 'delete'];

  constructor(
    fb: FormBuilder,
    private clientService: ClientService,
    public dialog: MatDialog,
    private processService: ProcessService,
    private protocolService: ProtocolQService,
    private route: ActivatedRoute,
    private router: Router,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private translate: TranslateService,
    private usersService: UsersService,
    private testQualificationTypeService: TestQualificationTypeService,
    private protocolQTestsService: ProtocolQTestsService
  ) {
    this.spinnerService.show();

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;

      if (id == null || isNaN(+id)) {
        this.cancel();
      }

      this.protocol = new ProtocolQ();
      if (+id === 0) {
        this.testQualificationTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: TestQualificationTypeShort[]) => {
          this.testQualificationTypeList = data;
        });

        this.spinnerService.hide();
      } else {
        this.protocolService.findOne(id).pipe(takeUntil(this.destroy$)).subscribe((result: ProtocolQ) => {
          if (result != null) {
            this.protocol = result;

            if (this.protocol == null) {
              this.cancel();
              return;
            } else {
              this.form = fb.group({
                idClient: [this.protocol.idClient, [Validators.required]],
                idProcess: [this.protocol.idProcess, [Validators.required]],
                projectNo: [this.protocol.projectNo, [Validators.required]],
                documentCode: [{ value: this.protocol.documentCode, disabled: true }],
                clientDocumentCode: [this.protocol.clientDocumentCode],
                projectManager: [this.protocol.usernameProjectManager],
                regUser: [{ value: this.protocol.regUser, disabled: true }],
                modUser: [{ value: this.protocol.modUser, disabled: true }],
                regFc: [{ value: this.protocol.regFc, disabled: true }],
                modFc: [{ value: this.protocol.modFc, disabled: true }],
                currentVersion: [{ value: this.protocol.currentVersion, disabled: true }],
                signedVersion: [{ value: this.protocol.signedVersion, disabled: true }],
                observations: [{ value: this.protocol.observations }],
                notes: [{ value: this.protocol.notes }]
              });

              this.spinnerService.show();
              this.protocolQTestsService.findByTestTypeId(this.protocol.id).pipe(takeUntil(this.destroy$))
              .subscribe((data: TestQualificationTypeShort[]) => {
                this.testQualificationTypeList = data;
                this.spinnerService.hide();
              });
            }

          } else {
            this.protocol = null;
          }
        }, () => {
          this.protocol = null;
          this.cancel();
        });
      }
    });

    this.form = fb.group({
      idClient: [this.protocol.idClient, [Validators.required]],
      idProcess: [this.protocol.idProcess, [Validators.required]],
      projectNo: [this.protocol.projectNo, [Validators.required]],
      documentCode: [{ value: this.protocol.documentCode, disabled: true }],
      clientDocumentCode: [this.protocol.clientDocumentCode],

      projectManager: [this.protocol.usernameProjectManager],

      regUser: [{ value: this.protocol.regUser, disabled: true }],
      modUser: [{ value: this.protocol.modUser, disabled: true }],
      regFc: [{ value: this.protocol.regFc, disabled: true }],
      modFc: [{ value: this.protocol.modFc, disabled: true }],
      currentVersion: [{ value: this.protocol.currentVersion, disabled: true }],
      signedVersion: [{ value: this.protocol.signedVersion, disabled: true }],
      observations: [{ value: this.protocol.observations }],
      notes: [{ value: this.protocol.notes }]
    });
  }

  ngOnInit(): void {
    this.clients = [];
    this.processes = [];
    this.processService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.processes = data);

    this.respField = [];
    this.usersService.findAllRespFromGroup().pipe(takeUntil(this.destroy$)).subscribe((data: User[]) => this.respField = data);

    this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => {
      this.clients = data;
      this.clientsFiltered = this.clients.slice();
    });
  }

  cancel(): void {
    void this.router.navigateByUrl('/qualificates/protocols');
  }

  canEditDocumentCode(): boolean {
    const allowedStatuses = [ProtocolStatus.PENDIENTE_FIRMA];

    return this.protocol.id != null && allowedStatuses.includes(this.protocol.idStatus);
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 1) {
      this.auditTab.getAudit();
    }
  }

  showAutomaticSign(): boolean {
     return false;
  }

  showManualSign(): boolean {
    return false;
  }

  showRevertSign(): boolean {
    return this.protocol.idStatus === ProtocolStatus.FIRMADO;
  }

  saveProtocol(): void {

    const errores: string[] = [];

    if (!this.form.valid) {
      errores.push(this.translate.instant('protocolEdit.form.error.notValid') as string);
    }

    if (errores.length === 0) {
      if (this.protocol.id == null) {
        this.save();
      } else {

        const showReason = (+this.protocol.signedVersion) !== 0;

        if (showReason) {

          const dialogRef = this.dialog.open(ProtocolEditConfirmSaveComponent, {
            minWidth: '30vw',
            data: {
              protocol: this.protocol,
              reason: this.protocol.reason
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: DialogDataConfirmSave) => {
            if (result != null) {
              this.protocol.reason = result.reason;
              this.save();
            }
          });

        } else {
          this.save();
        }

      }

    } else if (errores.length !== 0) {
      this.snackBarService.sendError(errores.join('\n'));
    }

  }

  createTestsSelected(test: TestQType): void {
    let isEdit = false;
    let isEditTest = false;
    let idStatus = this.protocol.idStatus;
    // eslint-disable-next-line max-len
    const result = this.protocol?.protocolQTest?.find(e => e.idTest === test?.id);
    if (result) {
      isEdit = true;
      if (result.protocolQTestItem) {
        isEditTest = true;
      }
    }
    const dialogRef = this.dialog.open(ProtocolQEditTestComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        idStatus,
        idItem: test.id,
        testQType: test,
        isEdit,
        isEditTest,
        data: result
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: ProtocolQTest) => {
      if (res) {
        this.protocol?.protocolQTest?.filter(e=> e.idTest === test.id).forEach(d => {
          d.testDescription = res.testDescription;
          d.regUser = res.regUser;
          d.regDate = res.regDate;
          d.modUser = res.modUser;
          d.modDate = res.modDate;
          d.reason = res.reason;
          d.imageTest = res.imageTest;
          d.protocolQTestItem = res.protocolQTestItem;
          if (res.protocolQTestProbeTraceability) {
            d.protocolQTestProbeTraceability = res.protocolQTestProbeTraceability;
          }
          if (res.protocolQTestResults) {
            d.protocolQTestResults = res.protocolQTestResults;}

          if (res.protocolQTestVerification) {
            d.protocolQTestVerification = res.protocolQTestVerification;
          }
          if (res.protocolQTestBioindicadores) {
            d.protocolQTestBioindicadores = res.protocolQTestBioindicadores;
          }
        })
      }
      // this.groupSelected = [...res];
    });
  }

  onSelectedItem(test: TestQType, index: number, event: MatCheckboxChange): void {
   if (event.checked) {
    if (!this.protocol?.protocolQTest) {
      this.protocol.protocolQTest = [];
    }
    const protocolQTest = new ProtocolQTest();
    protocolQTest.idTest = test.id;
    this.protocol?.protocolQTest.push(protocolQTest);
   } else {
    this.protocol.protocolQTest = this.protocol.protocolQTest.filter(e => e.idTest !== test.id);
   }
  }

  openNewRefDoc(): void {
    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: new ReferenceDocument(),
        isEdit: false,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result != null) {
        this.protocol.referenceDocs.push(result);

        this.refDocTable.renderRows();
      }
    });
  }

  editRefDoc(refDocIndex: number): void {

    const refDoc = this.protocol.referenceDocs[refDocIndex];

    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: _.cloneDeep(refDoc),
        isEdit: true,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result != null) {
        this.protocol.referenceDocs[refDocIndex] = result;

        this.refDocTable.renderRows();
      }
    });
  }

  removeRefDoc(refDocIndex: number): void {
    this.protocol.referenceDocs.splice(refDocIndex, 1);

    this.refDocTable.renderRows();
  }

  openDialogEquipment(): void {
    const dialogRef = this.dialog.open(ProtocolEditEquipmentDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipment: new EquipmentQualificates(),
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentQualificates) => {
      if (result != null) {
        if (this.protocol.equipments == null) {
          this.protocol.equipments = [];
        }
        this.protocol.equipments.push(result);
      }
    });
  }

  openDialogEditEquipment(idx: number): void {
    event.stopPropagation();

    const eq = this.protocol.equipments[idx];

    const dialogRef = this.dialog.open(ProtocolEditEquipmentDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipment: _.cloneDeep(eq),
        isEdit: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentQualificates) => {
      if (result != null) {
        this.protocol.equipments[idx] = result;
      }
    });
  }


  deleteEquipment( index: number): void {
    event.stopPropagation();

    const message = this.translate.instant('protocolEdit.dialog.equipment.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.protocol.equipments.splice(index, 1);
        }
      }
    });

  }

  showAdjustTemplate(): void {
    const dialogRef = this.dialog.open(ProtocolQEditSectionTemplateComponent, {
      minWidth: '70%',
      maxHeight: '95vh',
      data: {
        protocol: !this.protocol.protocolQGeneralData ? new ProtocolQGeneralData(): this.protocol.protocolQGeneralData
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((protocol: ProtocolQGeneralData
    ) => {
      if (protocol != null) {
        this.protocol.protocolQGeneralData = protocol;
      }
  });
}

  downloadPdf(): void {
    this.dialog.open(ProtocolQEditGenerateReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: { protocol: this.protocol }
    });
  }

  private save() {

    if (this.protocol.idStatus === ProtocolStatus.PENDIENTE_FIRMA){
      const dialogRef = this.dialog.open(ReasonDialogComponent, {
        minWidth: '40%',
        maxHeight: '95vh',
        data: {}
      });
    
      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
        if (result != null) {
        this.protocol.reason = result;
        this.saveAll();
        }
      });      
    } else {
      this.saveAll();
    }
  }

  private saveAll() {
    const isNew = this.protocol.id == null;
        
    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
      if (token != null) {

        this.spinnerService.show();

        this.protocolService.save(this.protocol, token).pipe(takeUntil(this.destroy$)).subscribe((res: ProtocolQ) => {
          this.protocol = res;
          this.reloadPage();

          if (isNew) {
            this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.create.ok') as string);

            void this.router.navigateByUrl(`qualificates/protocol?id=${res.id}`);
          } else {
            this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.update.ok') as string);
          }

          this.spinnerService.hide();
        }, err => {
          if (err != null && err.error != null && typeof err.error === 'string') {
            this.snackBarService.sendError(err.error as string);
          } else if (isNew) {
            this.snackBarService.sendError(this.translate.instant('protocolEdit.form.create.error') as string);
          } else {
            this.snackBarService.sendError(this.translate.instant('protocolEdit.form.update.error') as string);
          }

          this.spinnerService.hide();
        });

      } else {
        this.spinnerService.hide();
      }
    });
  }

  private reloadPage(idProtocol = this.protocol.id) {
    if (idProtocol == null) {
      idProtocol = this.protocol.id;
    }

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    void this.router.navigateByUrl(`qualificates/protocol?id=${idProtocol}`);
  }
}
