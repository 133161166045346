export class TestQConfig {
    id: number;
    idTestType: number;
    expectedResult: boolean;
    obtainedResult: boolean;
    probeTraceability: boolean;
    bioTraceability: boolean;
    testResult: boolean;
    testVerification: boolean;
    testGeneralData:boolean;
    item: boolean;
}