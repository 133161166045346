import * as XLSX from 'xlsx';
import * as moment from 'moment';

import { EssayExecutionAirResultParticles, ResultExcelParticles } from '../model/essayExecutionResultAir';
import { Variable, VariableTypeEnum } from '../model/variable';

import { AppTypeEnum } from '../model/appType';
import { ArrayUtils } from './arrayUtils';
import { AttachmentThermalService } from '../services/attachmentThermal.service';
import { CalibrationResponsibleEnum } from '../model/calibration';
import { Client } from '../model/client';
import { DateUtils } from './dateUtils';
import { InternalEquipment } from '../model/internalEquipment';
import { InternalEquipmentStatusEnum } from '../model/internalEquipmentStatus';
import { NumberUtils } from './numberUtils';
import { SensorData } from '../model/sensorData';
import { StringUtils } from './stringUtils';
import { TranslateService } from '@ngx-translate/core';
import { VariableTypeService } from '../services/variableType.service';
import { firstValueFrom } from 'rxjs';
import { getJsDateFromExcel } from 'excel-date-to-js';
import { InternalEquipmentCustomFieldsService } from '../services/internalEquipmentCustomFields.service';
import { InternalEquipmentCustomField } from '../model/internalEquipmentCustomField';
import { InternalEquipmentCustomFieldValue } from '../model/internalEquipmentCustomFieldValue';
import { InternalEquipmentFolderService } from '../services/internalEquipmentFolderService';
import { InternalEquipmentFolder } from '../model/internalEquipmentFolder';

const daysToSec = 24 * 60 * 60;

export interface ConvertedFile {
    file: File;
    raw: boolean;
}

export class ExcelUtils {
    public static async excelToSensorData(
        file: File, attachmentThermalService: AttachmentThermalService, idVariable?: number, multiple?: boolean,
        totalSensors?: number): Promise<SensorData[]> {
        const res = await this.convertToCsv(file, attachmentThermalService);
        const data = res.file;
        const raw = res.raw;
        const dataExcel = await this.loadExcel(data, raw);
        return this.loadSensorData(dataExcel, idVariable, multiple, totalSensors);
    }

    public static async excelToInternalEquipment(file: File, variableTypeService: VariableTypeService, translate: TranslateService):
        Promise<InternalEquipment[]> {
        const data = await this.loadExcel(file, false);
        return this.loadInternalEquipment(data, variableTypeService, translate);
    }

    public static async excelToInternalEquipmentCustomField(file: File, variableTypeService: VariableTypeService,
        translate: TranslateService, internalEquipmentCustomFieldsService: InternalEquipmentCustomFieldsService,
         internalEquipmentFolderService: InternalEquipmentFolderService):
    Promise<InternalEquipment[]> {
    const data = await this.loadExcel(file, false);
    return this.loadInternalEquipmentCustomField(data, variableTypeService, translate, internalEquipmentCustomFieldsService,
        internalEquipmentFolderService);
}


    public static async excelToParticleCount(file: File, attachmentThermalService: AttachmentThermalService):
        Promise<ResultExcelParticles> {
        const res = await this.convertToCsv(file, attachmentThermalService);
        const data = res.file;
        const raw = res.raw;
        const dataExcel = await this.loadExcel(data, raw);

        if (!dataExcel.some(lines => lines.length > 1)) {
            for (let i = 0; i < dataExcel.length; i++) {
                const line = dataExcel[i].toString();
                dataExcel[i] = line.split('.');
            }
        }

        return this.loadResultParticles(dataExcel);
    }

    public static async excelToClient(file: File): Promise<Client[]> {
        const data = await this.loadExcel(file, false);
        return this.loadClient(data);
    }

    private static convertToCsv(file: File, attachmentThermalService: AttachmentThermalService): Promise<ConvertedFile> {
        return new Promise((resolve, reject) => {
            if (file.name == null || file.name.endsWith('.csv') || file.name.endsWith('.pdf')) {
                resolve({ file, raw: file.name.endsWith('.csv') });
            } else {
                attachmentThermalService.convertExcelToCsv(file).subscribe((res: File) => resolve({ file: res, raw: true }),
                    err => reject(err));
            }

        });
    }

    private static loadExcel(file: File, raw: boolean): Promise<any[][]> {
        return new Promise((resolve, reject) => {

            if (typeof file === 'string') {
                resolve(this.stringToWb(file, raw));
            } else {
                const reader: FileReader = new FileReader();

                reader.onload = (e: any) => resolve(this.stringToWb(e.target.result as string, raw));

                reader.onerror = (error: any) => reject(error);

                reader.readAsBinaryString(file);
            }
        });
    }

    private static stringToWb(str: string, raw: boolean): any[][] {
        let data: any[][];

        /* read workbook */
        const bstr: string = str;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', raw });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        const options: XLSX.Sheet2JSONOpts = { header: 1, raw: true };

        /* save data */
        data = (XLSX.utils.sheet_to_json(ws, options));

        data = data.map((s: any[]) => {
            if (s.length === 1 && typeof s[0] === 'string') {
                const dat = s[0];
                s = dat.split(';');
            }

            return s as any[][];
        });

        return data;
    }

    private static async loadInternalEquipmentCustomField(data: any[][], variableTypeService: VariableTypeService,
        translate: TranslateService, internalEquipmentCustomFieldsService: InternalEquipmentCustomFieldsService,
        internalEquipmentFolderService: InternalEquipmentFolderService):
        Promise<InternalEquipment[]> {
        const res: InternalEquipment[] = [];
        const mapVariables: Map<string, number> = new Map();
        const listVariables = await firstValueFrom(variableTypeService.findAll()) as Variable[];
        // eslint-disable-next-line max-len
        const listCustomFields = await firstValueFrom(internalEquipmentCustomFieldsService.findAll()).then(er => er?.content) as InternalEquipmentCustomField[];
        const listFolders = await firstValueFrom(internalEquipmentFolderService.getAll()) as InternalEquipmentFolder[];

        listVariables.forEach(v => mapVariables.set(translate.instant('variable.'.concat(v.translation)) as string, v.id));

        // Empezamos en 1 para saltarnos la primera fila, que es de cabeceras
        for (let i = 1; i < data.length; i++) {
            const rowNum = i + 1;
            const row = data[i];

            const eq = new InternalEquipment();
            eq.name = this.getDataFromCell(row, 0);
            eq.group = this.getDataFromCell(row, 1);
            eq.equipment = this.getDataFromCell(row, 2);
            eq.maker = this.getDataFromCell(row, 3);
            eq.model = this.getDataFromCell(row, 4);
            eq.serialNum = this.getDataFromCell(row, 5);

            const responsible = this.getDataFromCell(row, 6);
            if (responsible != null && responsible.trim().toLowerCase() === 'interno') {
                eq.responsible = CalibrationResponsibleEnum.INTERNAL;
            } else if (responsible != null && responsible.trim().toLowerCase() === 'externo') {
                eq.responsible = CalibrationResponsibleEnum.EXTERNAL;
            }

            eq.supplier = this.getDataFromCell(row, 7);
            eq.fee = this.getNumberFromCell(row, 8);

            const idApps: number[] = [];
            const applications = this.getDataFromCell(row, 9)?.toLowerCase().split(',').map(a => a.trim());
            if (!ArrayUtils.isEmpty(applications)) {
                applications.forEach(app => {
                    if (app === 'thermal') {
                        idApps.push(AppTypeEnum.THERMAL);
                    } else if (app === 'calibrates') {
                        idApps.push(AppTypeEnum.CALIBRATES);
                    } else if (app === 'air') {
                        idApps.push(AppTypeEnum.AIR);
                    }
                });
            }
            eq.idsApps = idApps;

            let idVars: number[] = [];
            const variablesSelected = this.getDataFromCell(row, 10)?.split(',').map(a => a.trim());

            if (!ArrayUtils.isEmpty(variablesSelected)) {
                idVars = variablesSelected.map(vari => mapVariables.get(vari)).filter(a => a != null);
            }
            eq.idsVariables = idVars;

            eq.equipmentRange = this.getDataFromCell(row, 11);
            eq.useRange = this.getDataFromCell(row, 12);
            eq.resolution = this.getDataFromCell(row, 13);
            eq.tolerance = this.getDataFromCell(row, 14);
            eq.calibrationFrequency = this.getDataFromCell(row, 15);
            eq.acquisitionDate = this.getDateFromCell(row, 16);

            eq.calibrateDate = this.getDateFromCell(row, 17);
            eq.expirationDate = this.getDateFromCell(row, 18);

            eq.certificateNum = this.getDataFromCell(row, 19);

            const status = this.getDataFromCell(row, 20);
            if (status != null && status.trim().toLowerCase() === 'en uso') {
                eq.idStatus = InternalEquipmentStatusEnum.EN_USO;
            } else if (status != null && status.trim().toLowerCase() === 'fuera de uso') {
                eq.idStatus = InternalEquipmentStatusEnum.FUERA_DE_USO;
            } else if (status != null && status.trim().toLowerCase() === 'caducado') {
                eq.idStatus = InternalEquipmentStatusEnum.CADUCADO;
            } else if (status != null && status.trim().toLowerCase() === 'extendido') {
                eq.idStatus = InternalEquipmentStatusEnum.EXTENDIDO;
            // eslint-disable-next-line max-len
            } else if (status != null && status.trim().toLowerCase() === 'pendiente de certificado') {
                eq.idStatus = InternalEquipmentStatusEnum.PENDING_CERTIFICATE;
            }
            // eslint-disable-next-line max-len
             else if (status != null && (status.trim().toLowerCase() === 'pendiente de revisión' || status.trim().toLowerCase() === 'pendiente de revision')) {
                eq.idStatus = InternalEquipmentStatusEnum.PENDING_REVIEW;
            }

            eq.observations = this.getDataFromCell(row, 21);

            let columnNumber = 22;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, max-len
            if (data[0][columnNumber]?.toLowerCase() === translate.instant('internalEquipmentEdit.general.extendedDayCalibration.label')?.toLowerCase()) {
                eq.expansionDay = this.getNumberFromCell(row, 22);
                if (eq.expansionDay && eq.expansionDay > 0) {
                    const result = moment(eq.expirationDate, 'YYYY/MM/DD').add(eq.expansionDay, 'd').format('YYYY/MM/DD');
                    eq.extendedExpirationDate = DateUtils.anyToDate(result);
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    eq.idStatus = InternalEquipmentStatusEnum.EXTENDIDO;
                }
                columnNumber += 1;
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, max-len
            if (data[0][columnNumber]?.toLowerCase() === translate.instant('internalEquipmentEdit.general.extendedDateCalibration.label')?.toLowerCase()) {
                // eq.extendedExpirationDate = this.getDateFromCell(row, 23);
                columnNumber += 1;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                // eq.idStatus = InternalEquipmentStatusEnum.EXTENDIDO;
            }

            const idFolders: number[] = [];
            const folders = this.getDataFromCell(row, columnNumber)?.toLowerCase().split(',').map(a => a.trim());
            if (!ArrayUtils.isEmpty(folders)) {
                folders.forEach(app => {
                    const idFold =  listFolders.find(d => d.name.toLowerCase() === app?.toLowerCase());
                    if (idFold) {
                        idFolders.push(idFold.id);
                    }
                });
                columnNumber += 1;
            }
            eq.idsFolder = idFolders;

            for (let index = columnNumber; index < data[0].length; index++) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const element = data[0][index];

                const resultCustom = listCustomFields.find(e => e.name.toLowerCase() === element?.toLowerCase());

                if (resultCustom) {
                    const value = this.getDataFromCell(row, index);
                    const custom = new InternalEquipmentCustomFieldValue();
                    custom.idCustomField = resultCustom.id;
                    custom.name = resultCustom.name;
                    custom.idType = resultCustom.idType;
                    if (value?.toLowerCase() === 'x') {
                        custom.value = 'true';
                    } else {
                        custom.value = value;
                    }

                    if (!eq.customFields) {
                        eq.customFields = [];
                    }
                    eq.customFields.push(custom);
                }
            }
            if (eq.name == null) {
                console.error(`El identificador es nulo para la fila ${rowNum}`);
            } else if (eq.equipment == null) {
                console.error(`El tipo de equipo es nulo para la fila ${rowNum}`);
            } else if (eq.maker == null) {
                console.error(`El fabricante es nulo para la fila ${rowNum}`);
            } else if (eq.model == null) {
                console.error(`El modelo es nulo para la fila ${rowNum}`);
            } else if (eq.responsible == null) {
                console.error(`El responsable de calibración es nulo para la fila ${rowNum}`);
            } else if (eq.idStatus == null) {
                console.error(`El estado del equipo es nulo para la fila ${rowNum}`);
            } else {
                const resultCustom = eq.customFields?.filter(p => p.value === null);
                if (resultCustom?.length > 0) {
                    console.error(`Los campos personalizados son nulos para la fila  ${rowNum}`);
                } else {
                   res.push(eq);
                }
            }
        }

        return res;
    }

    private static async loadInternalEquipment(data: any[][], variableTypeService: VariableTypeService,
        translate: TranslateService):
        Promise<InternalEquipment[]> {
        const res: InternalEquipment[] = [];

        const mapVariables: Map<string, number> = new Map();
        const listVariables = await firstValueFrom(variableTypeService.findAll()) as Variable[];
        listVariables.forEach(v => mapVariables.set(translate.instant('variable.'.concat(v.translation)) as string, v.id));

        // Empezamos en 1 para saltarnos la primera fila, que es de cabeceras
        for (let i = 1; i < data.length; i++) {
            const rowNum = i + 1;
            const row = data[i];

            const eq = new InternalEquipment();
            eq.name = this.getDataFromCell(row, 0);
            eq.group = this.getDataFromCell(row, 1);
            eq.equipment = this.getDataFromCell(row, 2);
            eq.maker = this.getDataFromCell(row, 3);
            eq.model = this.getDataFromCell(row, 4);
            eq.serialNum = this.getDataFromCell(row, 5);

            const responsible = this.getDataFromCell(row, 6);
            if (responsible != null && responsible.trim().toLowerCase() === 'interno') {
                eq.responsible = CalibrationResponsibleEnum.INTERNAL;
            } else if (responsible != null && responsible.trim().toLowerCase() === 'externo') {
                eq.responsible = CalibrationResponsibleEnum.EXTERNAL;
            }

            eq.supplier = this.getDataFromCell(row, 7);
            eq.fee = this.getNumberFromCell(row, 8);

            const idApps: number[] = [];
            const applications = this.getDataFromCell(row, 9)?.toLowerCase().split(',').map(a => a.trim());
            if (!ArrayUtils.isEmpty(applications)) {
                applications.forEach(app => {
                    if (app === 'thermal') {
                        idApps.push(AppTypeEnum.THERMAL);
                    } else if (app === 'calibrates') {
                        idApps.push(AppTypeEnum.CALIBRATES);
                    } else if (app === 'air') {
                        idApps.push(AppTypeEnum.AIR);
                    }
                });
            }
            eq.idsApps = idApps;

            let idVars: number[] = [];
            const variablesSelected = this.getDataFromCell(row, 10)?.split(',').map(a => a.trim());

            if (!ArrayUtils.isEmpty(variablesSelected)) {
                idVars = variablesSelected.map(vari => mapVariables.get(vari)).filter(a => a != null);
            }
            eq.idsVariables = idVars;

            eq.equipmentRange = this.getDataFromCell(row, 11);
            eq.useRange = this.getDataFromCell(row, 12);
            eq.resolution = this.getDataFromCell(row, 13);
            eq.tolerance = this.getDataFromCell(row, 14);
            eq.calibrationFrequency = this.getDataFromCell(row, 15);
            eq.acquisitionDate = this.getDateFromCell(row, 16);

            eq.calibrateDate = this.getDateFromCell(row, 17);
            eq.expirationDate = this.getDateFromCell(row, 18);

            eq.certificateNum = this.getDataFromCell(row, 19);

            const status = this.getDataFromCell(row, 20);
            if (status != null && status.trim().toLowerCase() === 'en uso') {
                eq.idStatus = InternalEquipmentStatusEnum.EN_USO;
            } else if (status != null && status.trim().toLowerCase() === 'fuera de uso') {
                eq.idStatus = InternalEquipmentStatusEnum.FUERA_DE_USO;
            } else if (status != null && status.trim().toLowerCase() === 'caducado') {
                eq.idStatus = InternalEquipmentStatusEnum.CADUCADO;
            } else if (status != null && status.trim().toLowerCase() === 'extendido') {
                eq.idStatus = InternalEquipmentStatusEnum.EXTENDIDO;
            // eslint-disable-next-line max-len
            } else if (status != null && status.trim().toLowerCase() === 'pendiente de certificado') {
                eq.idStatus = InternalEquipmentStatusEnum.PENDING_CERTIFICATE;
            // eslint-disable-next-line max-len
            } else if (status != null && (status.trim().toLowerCase() === 'pendiente de revisión' || status.trim().toLowerCase() === 'pendiente de revision')) {
                eq.idStatus = InternalEquipmentStatusEnum.PENDING_REVIEW;
            }

            eq.observations = this.getDataFromCell(row, 21);

            if (eq.name == null) {
                console.error(`El identificador es nulo para la fila ${rowNum}`);
            } else if (eq.equipment == null) {
                console.error(`El tipo de equipo es nulo para la fila ${rowNum}`);
            } else if (eq.maker == null) {
                console.error(`El fabricante es nulo para la fila ${rowNum}`);
            } else if (eq.model == null) {
                console.error(`El modelo es nulo para la fila ${rowNum}`);
            } else if (eq.responsible == null) {
                console.error(`El responsable de calibración es nulo para la fila ${rowNum}`);
            } else if (eq.idStatus == null) {
                console.error(`El estado del equipo es nulo para la fila ${rowNum}`);
            } else {
                res.push(eq);
            }
        }

        return res;
    }

    private static loadResultParticles(data: any[][]): ResultExcelParticles {
        const res = new ResultExcelParticles();

        if (!((data[0][0] as string).startsWith('SOLAIR ') || !(data[0][0] as string).startsWith('ApexP3'))) {
            return res;
        }

        const rawUnit = data[4][3] as string;
        const unit = rawUnit?.substring(rawUnit.indexOf('(') + 1, rawUnit.indexOf(')'));

        res.serialNum = data[1][0] as string;
        res.serialNum = res.serialNum?.replace(' ', '')?.replace(/\b\w+(?:\:)/, '')?.replace(' ', '');

        const colZeroOne = data[4].map((s: string) => s.trim()).findIndex(s => s === '0.1') || -1;
        const colZeroTwo = data[4].map((s: string) => s.trim()).findIndex(s => s === '0.2') || -1;
        const colZeroThree = data[4].map((s: string) => s.trim()).findIndex(s => s === '0.3') || -1;
        const colZeroFive = data[4].map((s: string) => s.trim()).findIndex(s => s === '0.5') || -1;
        const colOneZero = data[4].map((s: string) => s.trim()).findIndex(s => s === '1.0') || -1;
        // const colThreeZero = data[4].map((s: string) => s.trim()).findIndex(s => s === '3.0') || -1;
        const colFiveZero = data[4].map((s: string) => s.trim()).findIndex(s => s === '5.0') || -1;

        const colAlarm = data[4].map((s: string) => s.trim()).findIndex(s => s === 'Alarm') || -1;

        // Empezamos en 5 para saltarnos las primeras filas, que son de cabeceras
        for (let i = 5; i < data.length; i++) {
            const row = data[i];

            const dto = new EssayExecutionAirResultParticles();
            dto.date = this.getDateFromCell(row, 0);
            dto.location = this.getDataFromCell(row, 1);
            dto.samplingTime = this.getNumberFromCell(row, 2);
            dto.volume = this.getNumberFromCell(row, 3);
            dto.unit = unit;

            let factor = 1000 / dto.volume;

            if (unit?.toLowerCase() === 'ft3' || unit?.toLowerCase() === 'cfm') {
                factor = factor / 28.316846592;
            }

            dto.zeroOne = this.cellToParticles(row as string[], colZeroOne, factor);
            dto.zeroTwo = this.cellToParticles(row as string[], colZeroTwo, factor);
            dto.zeroThree = this.cellToParticles(row as string[], colZeroThree, factor);
            dto.zeroFive = this.cellToParticles(row as string[], colZeroFive, factor);
            dto.oneZero = this.cellToParticles(row as string[], colOneZero, factor);
            // dto.threeZero = this.cellToParticles(row as string[], colThreeZero, factor);
            dto.fiveZero = this.cellToParticles(row as string[], colFiveZero, factor);

            if (colAlarm > -1) {
                dto.alarm = this.cellToData(row as string[], colAlarm);
                dto.flow = this.cellToData(row as string[], colAlarm + 1);
                dto.laser = this.cellToData(row as string[], colAlarm + 2);
                dto.service = this.cellToData(row as string[], colAlarm + 3);
            }

            res.particles.push(dto);
        }

        return res;
    }

    private static loadClient(data: any[][]): Client[] {
        const res: Client[] = [];

        // Empezamos en 1 para saltarnos la primera fila, que es de cabeceras
        for (let i = 1; i < data.length; i++) {
            const row = data[i];

            const eq = new Client();
            eq.name = this.getDataFromCell(row, 0);
            eq.address = this.getDataFromCell(row, 1);
            eq.postalCode = this.getDataFromCell(row, 2);
            eq.city = this.getDataFromCell(row, 3);
            eq.state = this.getDataFromCell(row, 4);
            eq.country = this.getDataFromCell(row, 5);

            res.push(eq);
        }

        return res;
    }

    private static getDataFromCell(row: any[], index: number): string {
        let res: string = null;

        try {
            res = row[index] as string;
        } catch (ex) {
            res = null;
        }

        return res;
    }

    private static getNumberFromCell(row: any[], index: number): number {
        let res: number = null;

        try {
            res = row[index] as number;
        } catch (ex) {
            res = null;
        }

        return res;
    }

    private static getDateFromCell(row: any[], index: number): Date {
        let res: Date = null;
        const cell = row[index] as string;

        let colDay = 0;
        const colMonth = 1;
        let colYear = 2;

        try {
            // Si tiene el formato normal de fecha (dd/MM/yyyy), calculamos la fecha con ese formato,
            // Si por el contrario es un número, se trata del formato especial de Excel.
            if (cell.toString().includes('/')) {
                const isDateTime = cell.toString().includes(':');

                const dateStr = isDateTime ? cell.split(' ')[0] : cell;

                const dateSplit: string[] = dateStr.split('/');

                // Obtenemos los campos individuales para todas las partes de la fecha
                let dd = +dateSplit[colDay];

                if (dd > 100) {
                    colDay = 2;
                    colYear = 0;

                    dd = +dateSplit[colDay];
                }

                const mm = +dateSplit[colMonth] - 1;
                const yyyy = +dateSplit[colYear];

                // Creamos la fecha con Javascript
                if (isDateTime) {
                    const hourSplit: string[] = cell.split(' ')[1].split(':');

                    const hh = hourSplit[0] ? +hourSplit[0] : 0;
                    const min = hourSplit[1] ? +hourSplit[1] : 0;
                    const sec = hourSplit[2] ? +hourSplit[2] : 0;

                    res = new Date(yyyy, mm, dd, hh, min, sec);
                } else {
                    res = new Date(yyyy, mm, dd);
                }

            } else if (!isNaN(+cell)) {
                // Convertimos el dato Excel a fecha normal
                res = new Date(((+cell - (25567 + 1)) * 86400 * 1000) - 86400000);
            }
        } catch (ex) {
            res = null;
        }

        return res;
    }

    private static loadSensorData(data: any[][], idVariable: number = null, multiple = false, totalSensors?: number): SensorData[] {
        if (this.isRotronic(data)) {
            if (multiple) {
                throw new Error('No es posible la subida masiva en Rotronic');
            } else {
                return this.loadRotronic(data);
            }
        } else if (this.isEscort(data)) {
            if (multiple) {
                return this.loadEscort(data, idVariable, totalSensors);
            } else {
                return this.loadEscort(data, idVariable, 1);
            }
        } else if (this.isLethality(data)) {
            if (multiple) {
                return this.loadLethality(data, idVariable, totalSensors);
            } else {
                return this.loadLethality(data, idVariable, 1);
            }
        } else if (this.isCO2(data)) {
            if (multiple) {
                return this.loadCO2(data, idVariable, totalSensors);
            } else {
                return this.loadCO2(data, idVariable, 1);
            }
        } else if (this.isCO2_v2(data) || this.isCO2_v3(data)) {
            if (multiple) {
                return this.loadCO2_v2(data, idVariable, totalSensors);
            } else {
                return this.loadCO2_v2(data, idVariable, 1);
            }
        } else if (this.isGeneric(data)) {
            if (multiple) {
                return this.loadGeneric(data, idVariable, totalSensors);
            } else {
                return this.loadGeneric(data, idVariable, 1);
            }
        } else if (this.isMedinsaCsv(data)) {
            if (multiple) {
                throw new Error('No es posible la subida masiva en Medinsa');
            } else {
                return this.loadMedinsaCsv(data, idVariable);
            }
        } else if (this.isMedinsaXls(data)) {
            if (multiple) {
                throw new Error('No es posible la subida masiva en Medinsa');
            } else {
                return this.loadMedinsaXls(data, idVariable);
            }
        } else if (this.isItaiXls(data)) {
            if (multiple) {
                throw new Error('No es posible la subida masiva en ITAI');
            } else {
                return this.loadItaiXls(data, idVariable);
            }
        } else if (this.isTesto(data)) {
            if (multiple) {
                throw new Error('No es posible la subida masiva en Testo');
            } else {
                return this.loadTesto(data, idVariable);
            }
        } else {
            throw new Error('Formato desconocido');
        }
    }

    private static isRotronic(data: any[][]): boolean {
        return data[1][0] === 'Rotronic';
    }

    private static isEscort(data: any[][]): boolean {
        return data[0][0] === ' ndice' && data[0][1] === 'Tiempo  Transcurrido' && data[0][2] === 'Fecha';
    }

    private static isCO2(data: any[][]): boolean {
        return data[0][2] === 'CO2, %';
    }

    private static isCO2_v2(data: any[][]): boolean {
        return data[0][1] === 'CO2, %';
    }

    private static isCO2_v3(data: any[][]): boolean {
        return data[0][0] === 'CO2, %';
    }

    private static isLethality(data: any[][]): boolean {
        let res = data[0][0] === 'Fecha' && data[0][1] === 'Hora';

        if (res) {
            let s1 = data[0][2];
            let s2 = data[0][3];

            if (s1 != null && s2 != null) {
                if (typeof s1 !== 'string') {
                    s1 = s1.toString();
                }
                if (typeof s2 !== 'string') {
                    s2 = s2.toString();
                }

                s1 = s1.split(' ');
                s2 = s2.split(' ');

                const cond1 = s1[0] === s2[0];

                const cond2 = s1[2] === 'Temperatura(°C)';
                const cond3 = s2[2] === 'Fo(min.)';

                res = cond1 && cond2 && cond3;
            } else {
                res = false;
            }
        }

        return res;
    }

    private static isMedinsaCsv(data: any[][]): boolean {
        return data[0].join(';').toLowerCase() === 'ID;Date;Time;Channel 1[%rH];Channel 2[°C]'.toLowerCase();
    }

    private static isMedinsaXls(data: any[][]): boolean {
        return data[1].join(';').toLowerCase() === 'id;Date/time;Channel 1[%rH];Channel 2[Â°C]'.toLowerCase();
    }

    private static isItaiXls(data: any[][]): boolean {
        return data[2].join(' ').toLowerCase() === 'File created by software: tempbase'.toLowerCase();
    }

    private static isTesto(data: any[][]): boolean {
        return data[0][0] === 'Nombre del instrumento: ' && (data[1][0] as string).startsWith('Hora de inicio:');
    }

    private static isDataTrace(data: any[][]): boolean {
        return data[0][0] === 'Número de Serie:' && data[0][1] === 'ID de Prueba:';
    }

    private static isFluke(data: any[][]): boolean {
        return data[0][0] === 'Record #' && data[0][1] === 'Time';
    }

    private static isXLOG(data: any[][]): boolean {
        return data[3][0] === 'Fecha &amp;amp; Hora' && data[3][1] === 'Acción';
    }

    private static isTmiOrion(data: any[][]): boolean {
        let res = data[0][0] === 'Fecha' && data[0][1] === 'Hora';

        if (res) {
            let s1: string | string[] = data[0][2] as string;
            let s2: string | string[] = data[0][3] as string;

            if (s1 != null && s2 != null) {
                s1 = s1.split(' ');
                s2 = s2.split(' ');

                res = s1 === s2;
            }
        }

        return res;
    }

    private static isPirani1(data: any[][]): boolean {
        return data[1][0] === 'fecha' && data[1][1] === 'Tiempo' && data[1][3] === 'Presión';
    }

    private static isPirani2(data: any[][]): boolean {
        return data[1][0] === 'Date' && data[1][1] === 'Time' && data[1][3] === 'Pressure';
    }

    private static isGeneric(data: any[][]): boolean {
        const firstCell = (data[0][0] as string) || '';
        const secondCell = (data[0][1] as string) || '';

        const dateFields = ['fecha', 'date'];
        const timeFields = ['hora', 'time'];

        return dateFields.includes(firstCell.toLowerCase().trim()) && timeFields.includes(secondCell.toLowerCase().trim());
    }

    private static loadRotronic(data: any[][]): SensorData[] {
        const res: SensorData[] = [];
        let indexCols = -1;
        let indexData = -1;

        for (let i = 0; i < data.length; i++) {

            const firstCol = data[i][0] as string;

            // En el if filtramos que ya esté asignado porque solo queremos el primero
            // Sumamos 1 en la asignación porque nos interesa la siguiente fila
            if (indexCols === -1 && firstCol === '[#H]') {
                indexCols = i + 1;
            } else if (indexData === -1 && firstCol === '[#D]Medidas') {
                indexData = i + 1;
            } else if (indexCols > -1 && indexData > -1) {
                // Si ya tenemos ambos datos, cortamos el bucle para no perder tiempo
                break;
            }
        }

        let indexTemp = -1;
        let indexHum = -1;

        if (indexCols > -1) {
            for (let i = 0; i < data[indexCols].length; i++) {
                const cell = data[indexCols][i] as string;

                if (cell === 'Temperatura') {
                    indexTemp = i;
                }

                if (cell === 'Humedad') {
                    indexHum = i;
                }

                // En este bucle no es necesario cortar, ya que tendrá pocos datos
            }
        }

        if (indexData > -1) {
            for (let i = indexData; i < data.length; i++) {
                const row = data[i];

                let dateOnly: string | Date | number = row[0] as string;

                if (typeof dateOnly === 'object') {
                    dateOnly = this.dateToDateStr(dateOnly);
                } else if (!isNaN(+dateOnly)) {
                    dateOnly = this.excelNumberToDateStr(+dateOnly);
                }

                const date = this.excelDatesToDate(dateOnly, row[1] as string, i);

                if (date != null && indexTemp > -1) {
                    const dataTemp = new SensorData();
                    dataTemp.idVariable = VariableTypeEnum.TEMPERATURE;
                    dataTemp.date = date;

                    let value: number | string = row[indexTemp] as string;

                    if (isNaN(+value)) {
                        value = value.replace(',', '.');
                        value = (+value);
                    }

                    dataTemp.value = NumberUtils.fixPrecision(value);

                    res.push(dataTemp);
                }

                if (date != null && indexHum > -1) {
                    const dataHum = new SensorData();
                    dataHum.idVariable = VariableTypeEnum.HUMIDITY;
                    dataHum.date = date;
                    let value: number | string = row[indexHum] as string;

                    if (isNaN(+value)) {
                        value = value.replace(',', '.');
                        value = (+value);
                    }

                    dataHum.value = NumberUtils.fixPrecision(value);

                    res.push(dataHum);
                }
            }
        }

        return res;
    }

    private static loadEscort(data: any[][], idVariable: number, totalSensors: number = null): SensorData[] {
        const res: SensorData[] = [];

        // Siempre tendremos 5 columnas por fichero, por lo que si
        // dividimos el total de columnas entre 5, tendremos el número de sensores.
        let totalSensorsInFile = Math.floor(data[0].length / 5);

        if (totalSensors != null) {
            totalSensorsInFile = Math.min(totalSensorsInFile, totalSensors);
        }

        // Empezamos a contar en la segunda fila, ya que la primera es de cabeceras
        for (let i = 1; i < data.length; i++) {
            const row = data[i];

            for (let j = 0; j < totalSensorsInFile; j++) {
                const sensorData = new SensorData();

                const baseCol = j * 5;

                let dateOnly: string | Date | number = row[baseCol + 2] as string;
                let timeOnly: string | Date | number = row[baseCol + 3] as string;

                if (Object.prototype.toString.call(dateOnly) === '[object Date]') {
                    dateOnly = this.dateToDateStr(DateUtils.anyToDate(dateOnly));
                } else if (!isNaN(+dateOnly)) {
                    dateOnly = this.excelNumberToDateStr(+dateOnly);
                }

                if (dateOnly) {
                    dateOnly = dateOnly.trim();
                }

                if (Object.prototype.toString.call(timeOnly) === '[object Date]') {
                    timeOnly = this.dateToHourStr(DateUtils.anyToDate(timeOnly));
                } else if (!isNaN(+timeOnly)) {
                    timeOnly = this.excelNumberToTimeStr(+timeOnly);
                }

                if (timeOnly) {
                    timeOnly = timeOnly.trim();
                }

                const dateStr = `${dateOnly} ${timeOnly}`;
                const date = this.stringToDate(dateStr);

                if (date != null && !isNaN(date.getTime())) {
                    sensorData.idVariable = idVariable;
                    sensorData.posEquipment = j;
                    sensorData.date = date;

                    let value: number | string = row[baseCol + 4] as string;

                    if (typeof value === 'string') {
                        value = value.replace(',', '.');
                        value = (+value);
                    }

                    sensorData.value = NumberUtils.fixPrecision(value);

                    res.push(sensorData);
                }

            }
        }

        return res;
    }

    private static loadLethality(data: any[][], idVariable: number, totalSensors: number = null): SensorData[] {
        const res: SensorData[] = [];

        // Siempre tendremos 5 columnas por fichero, por lo que si
        // dividimos el total de columnas entre 5, tendremos el número de sensores.
        let totalSensorsInFile = Math.floor((data[0].length - 2) / 2);

        if (totalSensors != null) {
            totalSensorsInFile = Math.min(totalSensorsInFile, totalSensors);
        }

        // Empezamos a contar en la segunda fila, ya que la primera es de cabeceras
        for (let i = 1; i < data.length; i++) {
            const row = data[i];

            const dateOnly: string | Date | number = row[0] as string;
            const timeOnly: string | Date | number = row[1] as string;

            const date = this.excelDatesToDate(dateOnly, timeOnly, i);

            for (let j = 0; j < totalSensorsInFile && date != null && !isNaN(date.getTime()); j++) {
                const sensorData = new SensorData();

                sensorData.idVariable = idVariable;
                sensorData.posEquipment = j;
                sensorData.date = date;

                let index = j * 2 + 2;
                if (idVariable === VariableTypeEnum.LETHALITY) {
                    index++;
                }
                let value: number | string = row[index] as string;

                if (typeof value === 'string') {
                    value = value.replace(',', '.');
                    value = (+value);
                }

                sensorData.value = NumberUtils.fixPrecision(value);

                res.push(sensorData);
            }
        }

        return res;
    }

    private static loadGeneric(data: any[][], idVariable: number, totalSensors: number = null): SensorData[] {
        const res: SensorData[] = [];

        // Siempre tendremos 2 columnas fijas, por lo que restaremos 2 al número de columnas del documento.
        let totalSensorsInFile = data[0].length - 2;

        if (totalSensors != null) {
            totalSensorsInFile = Math.min(totalSensorsInFile, totalSensors);
        }

        // Empezamos a contar en la segunda fila, ya que la primera es de cabeceras
        for (let i = 1; i < data.length; i++) {
            const row = data[i];

            const dateOnly = row[0] as string;
            const timeOnly = row[1] as string;

            if ((typeof dateOnly === 'string' && StringUtils.isEmpty(dateOnly)) ||
                (typeof timeOnly === 'string' && StringUtils.isEmpty(timeOnly))) {
                continue;
            }

            const date = this.excelDatesToDate(dateOnly, timeOnly, i);

            for (let j = 0; j < totalSensorsInFile && date != null && !isNaN(date.getTime()); j++) {
                const sensorData = new SensorData();

                sensorData.idVariable = idVariable;
                sensorData.posEquipment = j;
                sensorData.date = date;

                let value: number | string = row[j + 2] as string;

                if (typeof value === 'string') {
                    value = value.replace(',', '.');
                    value = (+value);
                }

                sensorData.value = NumberUtils.fixPrecision(value);

                if (totalSensors > 1) {
                    // Validate With Regex _
                    sensorData.equipmentName = data[0][j + 2] as string;
                    sensorData.serialNum = data[0][j + 2] as string;

                    if (StringUtils.isNotEmpty(sensorData.equipmentName)) {
                        sensorData.equipmentName = sensorData.equipmentName.toString().trim();
                    }

                    if (StringUtils.isNotEmpty(sensorData.serialNum)) {
                        sensorData.serialNum = sensorData.serialNum.toString().trim();
                    }
                }

                res.push(sensorData);
            }
        }

        return res;
    }

    private static loadCO2(data: any[][], idVariable: number, totalSensors: number = null) {
        return this.loadCO2Generic(data, idVariable, totalSensors, 1);
    }

    private static loadCO2_v2(data: any[][], idVariable: number, totalSensors: number = null) {
        return this.loadCO2Generic(data, idVariable, totalSensors, 0);
    }

    private static loadCO2Generic(data: any[][], idVariable: number, totalSensors: number = null, offset: number = 2): SensorData[] {
        const res: SensorData[] = [];

        // Siempre tendremos 2 columnas fijas, por lo que restaremos 2 al número de columnas del documento.
        // En algunos casos raros esta resta puede dar -1, así que ponemos que al menos haya
        let totalSensorsInFile = Math.max(data[0].length - 2, 1);

        if (totalSensors != null) {
            totalSensorsInFile = Math.min(totalSensorsInFile, totalSensors);
        }

        // Empezamos a contar en la segunda fila, ya que la primera es de cabeceras
        for (let i = 1; i < data.length; i++) {
            const row = data[i];

            let dateStr: Date | string = row[offset] as string;

            if (Object.prototype.toString.call(dateStr) === '[object Date]') {
                dateStr = this.dateTimeToHourStr(DateUtils.anyToDate(dateStr));
            } else if (!isNaN(+dateStr)) {
                dateStr = this.excelNumberToDateTimeStr(+dateStr);
            }

            if (dateStr) {
                dateStr = dateStr.replace('  ', ' ').trim();
            }

            const date = this.stringToDate(dateStr);

            if (date != null && !isNaN(date.getTime())) {

                for (let j = 0; j < totalSensorsInFile; j++) {
                    const sensorData = new SensorData();

                    sensorData.idVariable = idVariable;
                    sensorData.posEquipment = j;
                    sensorData.date = date;

                    let value: string | number = row[j + offset + 1] as string;

                    if (typeof value === 'string') {
                        value = +(value.replace(',', '.'));
                    }

                    sensorData.value = NumberUtils.fixPrecision(value);

                    res.push(sensorData);
                }
            }
        }

        return res;
    }

    private static loadMedinsaCsv(data: any[][], idVariable: number): SensorData[] {
        const res: SensorData[] = [];

        const dataColumn: number = VariableTypeEnum.HUMIDITY === idVariable ? 2 : 3;

        for (const row of data) {

            if (ArrayUtils.isEmpty(row) || isNaN(+row[0])) {
                continue;
            }

            let dateStr: Date | string = row[1] as string;
            let value: number | string = row[dataColumn] as string;

            if (Object.prototype.toString.call(dateStr) === '[object Date]') {
                dateStr = this.dateTimeToHourStr(DateUtils.anyToDate(dateStr));
            } else if (!isNaN(+dateStr)) {
                dateStr = this.excelNumberToDateTimeStr(+dateStr);
            }

            if (dateStr) {
                dateStr = dateStr.replace('  ', ' ').trim();
            }
            const date = this.stringToDate(dateStr);

            if (value != null) {
                value = value.toString().replace(',', '.');
                if (!isNaN(+value)) {
                    value = (+value);
                }
            } else {
                continue;
            }

            const sensorData = new SensorData();
            sensorData.value = NumberUtils.fixPrecision(value);
            sensorData.date = date;
            sensorData.idVariable = idVariable;

            res.push(sensorData);
        }

        return res;
    }

    private static loadMedinsaXls(data: any[][], idVariable: number): SensorData[] {
        const res: SensorData[] = [];

        const dataColumn: number = VariableTypeEnum.HUMIDITY === idVariable ? 2 : 3;

        for (const row of data) {

            if (ArrayUtils.isEmpty(row) || isNaN(+row[0])) {
                continue;
            }

            let dateOnly: string | Date | number = row[1] as string;
            let timeOnly: string | Date | number = row[2] as string;
            let value: number | string = row[dataColumn] as string;

            if (Object.prototype.toString.call(dateOnly) === '[object Date]') {
                dateOnly = this.dateToDateStr(DateUtils.anyToDate(dateOnly));
            } else if (!isNaN(+dateOnly)) {
                dateOnly = this.excelNumberToDateStr(+dateOnly);
            }

            if (dateOnly) {
                dateOnly = dateOnly.trim();
            }

            if (Object.prototype.toString.call(timeOnly) === '[object Date]') {
                timeOnly = this.dateToHourStr(DateUtils.anyToDate(timeOnly));
            } else if (!isNaN(+timeOnly)) {
                timeOnly = this.excelNumberToTimeStr(+timeOnly);
            }

            if (timeOnly) {
                timeOnly = timeOnly.trim();
            }

            const dateStr = `${dateOnly} ${timeOnly}`;
            const date = this.stringToDate(dateStr);

            if (value != null) {
                value = value.toString().replace(',', '.');
                if (!isNaN(+value)) {
                    value = (+value);
                }
            } else {
                continue;
            }

            const sensorData = new SensorData();
            sensorData.value = NumberUtils.fixPrecision(value);
            sensorData.date = date;
            sensorData.idVariable = idVariable;

            res.push(sensorData);
        }

        return res;
    }

    private static loadItaiXls(data: any[][], idVariable: number): SensorData[] {
        const res: SensorData[] = [];
        let indexData = -1;

        for (let i = 0; i < data.length; i++) {

            const row = data[i].join(';');

            // En el if filtramos que ya esté asignado porque solo queremos el primero
            // Sumamos 1 en la asignación porque nos interesa la siguiente fila
            if (indexData === -1 && row.toLowerCase().startsWith('ID;Time;Temp'.toLowerCase())) {
                indexData = i + 1;
            } else if (indexData > -1) {
                // Si ya tenemos ambos datos, cortamos el bucle para no perder tiempo
                break;
            }
        }

        if (indexData > -1) {
            for (let i = indexData; i < data.length; i++) {
                const row = data[i];

                const date = this.stringToDate(row[1] as string);

                if (date != null) {
                    const dataTemp = new SensorData();
                    dataTemp.idVariable = idVariable;
                    dataTemp.date = date;

                    let value: number | string = row[2] as string;

                    if (isNaN(+value)) {
                        value = value.replace(',', '.');
                        value = (+value);
                    }

                    dataTemp.value = NumberUtils.fixPrecision(value);

                    res.push(dataTemp);
                }
            }
        }

        return res;
    }

    private static loadTesto(data: any[][], idVariable: number): SensorData[] {
        const res: SensorData[] = [];
        let indexData = -1;

        for (let i = 0; i < data.length; i++) {

            const firstCol = data[i][0] as string;
            const secondCol = data[i][1] as string;

            // En el if filtramos que ya esté asignado porque solo queremos el primero
            // Sumamos 1 en la asignación porque nos interesa la siguiente fila
            if (firstCol === 'id' && secondCol === 'Fecha-Hora') {
                indexData = i + 1;
                break;
            }
        }

        if (indexData > -1) {
            for (let i = indexData; i < data.length; i++) {
                const row = data[i];

                let dateStr: Date | string = row[1] as string;

                if (Object.prototype.toString.call(dateStr) === '[object Date]') {
                    dateStr = this.dateTimeToHourStr(DateUtils.anyToDate(dateStr));
                } else if (!isNaN(+dateStr)) {
                    dateStr = this.excelNumberToDateTimeStr(+dateStr);
                }

                if (dateStr) {
                    dateStr = dateStr.replace('  ', ' ').trim();
                }

                const date = this.stringToDate(dateStr);

                if (date != null) {
                    const dataTemp = new SensorData();
                    dataTemp.idVariable = idVariable;
                    dataTemp.date = date;

                    let value: number | string = row[2] as string;

                    if (isNaN(+value)) {
                        value = value.replace(',', '.');
                        value = (+value);
                    }

                    dataTemp.value = NumberUtils.fixPrecision(value);

                    res.push(dataTemp);
                }
            }
        }

        return res;
    }

    private static excelDatesToDate(dateOnly: Date | number | string, timeOnly: Date | number | string, rowIndex: number): Date {

        if (Object.prototype.toString.call(dateOnly) === '[object Date]') {
            // Caso tipo Date
            dateOnly = this.dateToDateStr(DateUtils.anyToDate(dateOnly));
        } else if (!isNaN(+dateOnly)) {
            // Caso número de Excel
            dateOnly = this.excelNumberToDateStr(+dateOnly);
        } else if (typeof dateOnly === 'string') {
            // Caso string
            dateOnly = this.excelStringToDateStr(dateOnly);
        }

        let dateOnlyStr: string = null;

        if (dateOnly) {
            dateOnlyStr = dateOnly.toString().trim();
        } else {
            return null;
        }

        if (Object.prototype.toString.call(timeOnly) === '[object Date]') {
            timeOnly = this.dateToHourStr(DateUtils.anyToDate(timeOnly));
        } else if (!isNaN(+timeOnly)) {
            timeOnly = this.excelNumberToTimeStr(+timeOnly);
        } else if (typeof timeOnly === 'string') {
            // Caso string
            timeOnly = this.excelStringToTimeStr(timeOnly);
        }

        let timeOnlyStr: string = null;

        if (timeOnly) {
            timeOnlyStr = timeOnly.toString().trim();
        } else {
            return null;
        }

        const dateStr = `${dateOnlyStr}  ${timeOnlyStr}` + ' +0:00';
        let date = moment(dateStr, 'MM/DD/YYYY HH:mm:ss ZZ').toDate();

        if (isNaN(date.getTime())) {
            console.error(`No se ha reconocido la fecha ${dateOnlyStr}, hora ${timeOnlyStr}. Fila: ${(rowIndex + 1)}`);
            date = null;
        }

        return date;
    }

    private static stringToDate(str: string): Date {
        let date = moment(str, 'DD/MM/YYYY HH:mm:ss').toDate();

        if (isNaN(date.getTime())) {
            date = moment(str, 'DD/MM/YYYY HH:mm:ss ZZ').toDate();
        }

        if (isNaN(date.getTime())) {
            date = moment(str, 'MM/DD/YYYY HH:mm:ss').toDate();
        }

        if (isNaN(date.getTime())) {
            date = moment(str, 'MM/DD/YYYY HH:mm:ss ZZ').toDate();
        }

        if (isNaN(date.getTime())) {
            date = moment(str, 'DD-MMM-YYYY HH:mm:ss').toDate();
        }

        if (isNaN(date.getTime())) {
            date = null;
        }

        return date;
    }

    private static excelNumberToDateStr(num: number): string {
        const rawDate: Date = new Date((num - 25569) * 86400 * 1000);

        const date: Date = new Date(rawDate.toDateString());

        return this.dateToDateStr(date);
    }

    private static excelStringToDateStr(str: string): string {
        let isDDMM = false;
        try {
            isDDMM = isDDMM || moment(str, 'DD/MM/YYYY', true).isValid();
            isDDMM = isDDMM || moment(str, 'DD/MM/YY', true).isValid();
        } catch (e) {

        }

        const data = str.split('/');

        if (data.length < 2) {
            return null;
        }

        let day = String(data[isDDMM ? 0 : 1]);
        let month = String(data[isDDMM ? 1 : 0]);
        let year = String(data[2]);

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        if (year.length < 3) {
            year = '20' + year;
        }

        return `${month}/${day}/${year}`;
    }

    private static excelNumberToDateTimeStr(num: number): string {
        const date = getJsDateFromExcel(num);
        return this.dateTimeToHourStr(new Date(date));
    }

    private static excelNumberToTimeStr(num: number): string {
        num = num * daysToSec;

        const date = new Date(1900, 0, 1);
        date.setSeconds(date.getSeconds() + num);

        return this.dateToHourStr(date);
    }

    private static dateToDateStr(date: Date): string {
        let month = String(date.getMonth() + 1);
        let day = String(date.getDate());
        const year = String(date.getFullYear());

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return `${day}/${month}/${year}`;
    }

    private static dateToHourStr(date: Date): string {
        let hour = `${date.getHours()}`;
        let minutes = `${date.getMinutes()}`;
        let seconds = `${date.getSeconds()}`;

        if (hour.length < 2) {
            hour = '0' + hour;
        }
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        return `${hour}:${minutes}:${seconds}`;
    }

    private static excelStringToTimeStr(str: string): string {
        str = str.replace(' AM', '');

        const isAM = str.includes(' PM') && !str.startsWith('12:');

        str = str.replace(' PM', '');

        const data = str.split(':');

        if (data.length < 2) {
            return null;
        }

        let hour = data[0];
        let minutes = data[1];
        let seconds = data[2];

        if (isAM) {
            hour = ((+hour) + 12).toString();
        }

        if (hour.includes('24')) {
            hour = '00';
        }

        if (hour.length < 2) {
            hour = '0' + hour;
        }
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        return `${hour}:${minutes}:${seconds}`;
    }

    private static dateTimeToHourStr(date: Date): string {
        return this.dateToDateStr(date) + ' ' + this.dateToHourStr(date);
    }

    private static cellToParticles(row: string[], column: number, factor: number): number {
        if (column < 0) {
            return null;
        }

        return Math.round(this.getNumberFromCell(row, column) * factor);
    }

    private static cellToData(row: string[], column: number): string {
        if (column < 0) {
            return null;
        }

        return this.getDataFromCell(row, column);
    }

    public static async excelToParticleCountValidate(file: File, attachmentThermalService: AttachmentThermalService, translateService:TranslateService):
    Promise<String> {
    const res = await this.convertToCsv(file, attachmentThermalService);
    const data = res.file;
    const raw = res.raw;
    const dataExcel = await this.loadExcel(data, raw);

    if (!dataExcel.some(lines => lines.length > 1)) {
        for (let i = 0; i < dataExcel.length; i++) {
            const line = dataExcel[i].toString();
            dataExcel[i] = line.split('.');
        }
    }

    return this.loadResultParticlesValidate(dataExcel,translateService);
}

private static loadResultParticlesValidate(data: any[][], translateService: TranslateService): String  {
    const res = new ResultExcelParticles();

    // Empezamos en 5 para saltarnos las primeras filas, que son de cabeceras
    for (let i = 5; i < data.length; i++) {
        const row = data[i];

        const dto = new EssayExecutionAirResultParticles();
        const location= this.getDataFromCell(row, 1);
        const result= res.particles.find(loc => loc.location === location);
        if (!result){
            dto.location = this.getDataFromCell(row, 1);
            res.particles.push(dto);
        } else{
            return translateService.instant('essayAir.excel.duplication')
        }
        
    }

    return null;
}

}
