import { FileUtils } from './fileUtils';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';

export class PhotoUtils {

    public static fixImage(file: File, imageCompress: NgxImageCompressService, callback: (res: File) => void): void {

        void FileUtils.toBase64(file).then((src: string) => {
            this.compressFile(src, imageCompress, (compresed: string) => {
                callback(FileUtils.toFile(compresed, file.name));
            });
        });
    }

    private static compressFile(image, imageCompress: NgxImageCompressService, callback: (str: string) => void) {
        void imageCompress.compressFile(image as string, DOC_ORIENTATION.Default, 50, 75).then(result => callback(result));
    }

}
