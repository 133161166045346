/* eslint-disable max-len */
import { RouterModule, Routes } from '@angular/router';

import { AirInterceptor } from './interceptors/airInterceptor';
import { AppPlanEditComponent } from './components/admin/app-plan-edit/app-plan-edit.component';
import { AppPlanListComponent } from './components/admin/app-plan-list/app-plan-list.component';
import { CalibratesInterceptor } from './interceptors/calibratesInterceptor';
import { CalibrationEditComponent } from './components/calibrations/calibration-edit/calibration-edit.component';
import { CalibrationListComponent } from './components/calibrations/calibration-list/calibration-list.component';
import { CalibrationPlanEditComponent } from './components/admin/calibration-plan-edit/calibration-plan-edit.component';
import { ChangePasswordComponent } from './components/shared/change-password/change-password.component';
import { ClientEditComponent } from './components/clients/client-edit/client-edit.component';
import { ClientListComponent } from './components/clients/client-list/client-list.component';
import { CookiesComponent } from './components/shared/cookies/cookies.component';
import { EditUserComponent } from './components/admin/edit-user/edit-user.component';
import { ErrorNotFoundComponent } from './components/shared/error/not-found/not-found.component';
import { ExecutionEditComponent as ExecutionAirEditComponent } from './components/air/execution-edit/execution-edit.component';
import { ExecutionListComponent as ExecutionAirListComponent } from './components/air/execution-list/execution-list.component';
import { ExecutionEditComponent } from './components/thermal/execution-edit/execution-edit.component';
import { ExecutionListComponent } from './components/thermal/execution-list/execution-list.component';
import { ForgottenPasswordComponent } from './components/shared/forgotten-password/forgotten-password.component';
import { GenericErrorComponent } from './components/shared/error/generic/generic.component';
import { GroupEditComponent } from './components/admin/group-edit/group-edit.component';
import { GroupsListComponent } from './components/admin/groups-list/groups-list.component';
import { HasToChangePasswordInterceptor } from './interceptors/hasToChangePassInterceptor';
import { InternalEquipmentEditComponent } from './components/internal-equipments/internal-equipment-edit/internal-equipment-edit.component';
import { InternalEquipmentListComponent } from './components/internal-equipments/internal-equipment-list/internal-equipment-list.component';
import { IsAdminInterceptor } from './interceptors/isAdminInterceptor';
import { IsLoggedInterceptor } from './interceptors/isLoggedInterceptor';
import {
  IsothermalCharacterizationEditComponent
} from './components/calibrations/isothermal-characterization-edit/isothermal-characterization-edit.component';
import {
  IsothermalCharacterizationListComponent
} from './components/calibrations/isothermal-characterization-list/isothermal-characterization-list.component';
import { LoginComponent } from './components/login/login.component';
import { MaintenanceModeComponent } from './components/shared/maintenance-mode/maintenance-mode.component';
import { ManageUsersComponent } from './components/admin/manage-users/manage-users.component';
import { NgModule } from '@angular/core';
import { NotificationEditComponent } from './components/admin/notification-edit/notification-edit.component';
import { NotificationListComponent } from './components/admin/notification-list/notification-list.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { OldBrowserComponent } from './components/shared/old-browser/old-browser.component';
import { OldBrowserInterceptor } from './interceptors/oldBrowserInterceptor';
import { PaymentCompleteComponent } from './components/payments/payment-complete/payment-complete.component';
import { ProfileComponent } from './components/shared/profile/profile.component';
import { ProtocolEditComponent as ProtocolAirEditComponent } from './components/air/protocol-edit/protocol-edit.component';
import { ProtocolListComponent as ProtocolAirListComponent } from './components/air/protocol-list/protocol-list.component';
import { ProtocolEditComponent } from './components/thermal/protocol-edit/protocol-edit.component';
import { ProtocolListComponent } from './components/thermal/protocol-list/protocol-list.component';
import { RestorePasswordComponent } from './components/shared/restore-password/restore-password.component';
import { SendNotificationsComponent } from './components/admin/send-notifications/send-notifications.component';
import { SignupComponent } from './components/payments/signup/signup.component';
import { ThermalInterceptor } from './interceptors/thermalInterceptor';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PrincipalComponent } from './components/principal/principal.component';
import { HomeAirComponent } from './components/home/home-air/home-air.component';
import { HomeThermalComponent } from './components/home/home-thermal/home-thermal.component';
import { HomeCalibratesComponent } from './components/home/home-calibrates/home-calibrates.component';
import { ProcedureListComponent } from './components/calibrations/procedures/procedure-list/procedure-list.component';
import { ProtocolQListComponent } from './components/qualificates/protocol-list/protocol-list.component';
import { HomeQualificatesComponent } from './components/home/home-qualificates/home-qualificates.component';
import { QualificatesInterceptor } from './interceptors/qualificatesInterceptor';
import { ProtocolQEditComponent } from './components/qualificates/protocol-edit/protocol-edit.component';

const commonInterceptors = [IsLoggedInterceptor, HasToChangePasswordInterceptor, OldBrowserInterceptor];

const routes: Routes = [
  // Login
  { path: 'login', component: LoginComponent, pathMatch: 'prefix' },

  // Forgotten password
  { path: 'forgotPassword', component: ForgottenPasswordComponent, pathMatch: 'full', canActivate: [OldBrowserInterceptor] },

  // Forgotten password
  { path: 'restorePassword', component: RestorePasswordComponent, pathMatch: 'prefix', canActivate: [OldBrowserInterceptor] },

  // Principal
  { path: '', component: PrincipalComponent, canActivate: [...commonInterceptors]},
  { path: 'calibrates', component: HomeCalibratesComponent, canActivate: [...commonInterceptors]},
  { path: 'air', component: HomeAirComponent, canActivate: [...commonInterceptors]},
  { path: 'air/protocols', component: ProtocolAirListComponent, canActivate: [...commonInterceptors, AirInterceptor] },
  { path: 'air/protocol', component: ProtocolAirEditComponent, canActivate: [...commonInterceptors, AirInterceptor] },
  { path: 'air/executions', component: ExecutionAirListComponent, canActivate: [...commonInterceptors, AirInterceptor] },
  { path: 'air/execution', component: ExecutionAirEditComponent, canActivate: [...commonInterceptors, AirInterceptor] },
  { path: 'air/internalEquipments', component: InternalEquipmentListComponent, pathMatch: 'full', canActivate: [...commonInterceptors] },
  { path: 'air/internalEquipment', component: InternalEquipmentEditComponent, pathMatch: 'prefix', canActivate: [...commonInterceptors] },
  { path: 'thermal', component: HomeThermalComponent, canActivate: [...commonInterceptors]},
  { path: 'thermal/executions', component: ExecutionListComponent, canActivate: [...commonInterceptors] },
  { path: 'thermal/execution', component: ExecutionEditComponent, canActivate: [...commonInterceptors, ThermalInterceptor] },
  { path: 'thermal/protocols', component: ProtocolListComponent, pathMatch: 'prefix', canActivate: [...commonInterceptors, ThermalInterceptor] },
  { path: 'thermal/protocol', component: ProtocolEditComponent, pathMatch: 'prefix', canActivate: [...commonInterceptors, ThermalInterceptor] },
  { path: 'thermal/internalEquipments', component: InternalEquipmentListComponent, pathMatch: 'full', canActivate: [...commonInterceptors] },
  { path: 'thermal/internalEquipment', component: InternalEquipmentEditComponent, pathMatch: 'prefix', canActivate: [...commonInterceptors] },
  { path: 'thermal/isothermalCharacterizations', component: IsothermalCharacterizationListComponent, pathMatch: 'full', canActivate: [...commonInterceptors]},
  { path: 'thermal/isothermalCharacterization', component: IsothermalCharacterizationEditComponent, pathMatch: 'prefix', canActivate: [...commonInterceptors]},
  { path: 'calibrates/isothermalCharacterizations', component: IsothermalCharacterizationListComponent, pathMatch: 'full', canActivate: [...commonInterceptors]},
  { path: 'calibrates/isothermalCharacterization', component: IsothermalCharacterizationEditComponent, pathMatch: 'prefix', canActivate: [...commonInterceptors]},
  // Caracterización de medios isotermos
  { path: 'calibrates/calibrations', component: CalibrationListComponent, pathMatch: 'full', canActivate: [...commonInterceptors, CalibratesInterceptor]},
  { path: 'calibrates/calibration', component: CalibrationEditComponent, pathMatch: 'prefix',  canActivate: [...commonInterceptors, CalibratesInterceptor]},
  { path: 'calibrates/internalEquipments', component: InternalEquipmentListComponent, pathMatch: 'full', canActivate: [...commonInterceptors] },
  { path: 'calibrates/internalEquipment', component: InternalEquipmentEditComponent, pathMatch: 'prefix', canActivate: [...commonInterceptors] },
  // Equipos externos
  { path: 'calibrates/externalEquipments', component: InternalEquipmentListComponent, pathMatch: 'full', canActivate: [...commonInterceptors, CalibratesInterceptor]},
  { path: 'calibrates/externalEquipment', component: InternalEquipmentEditComponent, pathMatch: 'prefix', canActivate: [...commonInterceptors, CalibratesInterceptor]},
  // Clientes
  { path: 'clients', component: ClientListComponent, pathMatch: 'full', canActivate: [...commonInterceptors] },
  { path: 'client', component: ClientEditComponent, pathMatch: 'prefix', canActivate: [...commonInterceptors] },
  // Qualificaciones
  { path: 'qualificates', component: HomeQualificatesComponent, canActivate: [...commonInterceptors]},
  { path: 'qualificates/protocols', component: ProtocolQListComponent, canActivate: [...commonInterceptors, QualificatesInterceptor] },
  { path: 'qualificates/protocol', component: ProtocolQEditComponent, canActivate: [...commonInterceptors, QualificatesInterceptor] },
  // Perfil de usuario
  { path: 'perfil', component: ProfileComponent, pathMatch: 'full', canActivate: [...commonInterceptors] },

  { path: 'profile/changePassword', component: ChangePasswordComponent, pathMatch: 'full', canActivate: [IsLoggedInterceptor] },

  // Notificaciones
  { path: 'notifications', component: NotificationsComponent, pathMatch: 'full', canActivate: [...commonInterceptors] },

  // Gestión de usuarios
  {
    path: 'admin/manageUsers', component: ManageUsersComponent, pathMatch: 'full',
    canActivate: [...commonInterceptors, IsAdminInterceptor]
  },

  // Edición de usuario
  { path: 'admin/user', component: EditUserComponent, pathMatch: 'prefix', canActivate: [...commonInterceptors, IsAdminInterceptor] },

  // Gestión de grupos
  {
    path: 'admin/manageGroups', component: GroupsListComponent, pathMatch: 'full',
    canActivate: [...commonInterceptors, IsAdminInterceptor]
  },

  // Edición de grupo
  { path: 'admin/group', component: GroupEditComponent, pathMatch: 'prefix', canActivate: [...commonInterceptors, IsAdminInterceptor] },

  // Gestión de planes de pago
  {
    path: 'admin/manageAppPlan', component: AppPlanListComponent, pathMatch: 'full',
    canActivate: [...commonInterceptors, IsAdminInterceptor]
  },

  // Edición de planes de pago
  { path: 'admin/appPlan', component: AppPlanEditComponent, pathMatch: 'prefix', canActivate: [...commonInterceptors, IsAdminInterceptor] },

  // Gestión de notificaciones
  {
    path: 'admin/notifications', component: NotificationListComponent, pathMatch: 'full',
    canActivate: [...commonInterceptors, IsAdminInterceptor]
  },

  // Edición de notificaciones
  {
    path: 'admin/notification', component: NotificationEditComponent, pathMatch: 'prefix',
    canActivate: [...commonInterceptors, IsAdminInterceptor]
  },
  // Gestion Plan de calibraciones
  {
    path: 'admin/calibrationPlan', component: CalibrationPlanEditComponent, pathMatch: 'prefix',
    canActivate: [...commonInterceptors, IsAdminInterceptor]
  },
  // Enviar notificaciones
  {
    path: 'admin/sendNotifications', component: SendNotificationsComponent, pathMatch: 'full',
    canActivate: [...commonInterceptors, IsAdminInterceptor]
  },

  {
    path: 'admin/procedure', component: ProcedureListComponent, pathMatch: 'full',
    canActivate: [...commonInterceptors, IsAdminInterceptor]
  },

  // Página para cuando entre un usuario con un navegador antiguo
  { path: 'old-browser', component: OldBrowserComponent, pathMatch: 'full' },

  // Página de cookies
  { path: 'cookies', component: CookiesComponent, pathMatch: 'full' },

  // Sign-up
  { path: 'signup', component: SignupComponent, pathMatch: 'prefix' },

  // Sign-up
  { path: 'payment-complete', component: PaymentCompleteComponent, pathMatch: 'prefix' },

  // Maintenance mode
  { path: 'maintenance', component: MaintenanceModeComponent, pathMatch: 'full', canActivate: [OldBrowserInterceptor] },

  // Errors
  { path: 'error', component: GenericErrorComponent, pathMatch: 'full' },
  { path: '401', component: GenericErrorComponent, pathMatch: 'full' },
  { path: '403', component: GenericErrorComponent, pathMatch: 'full' },
  { path: '404', component: ErrorNotFoundComponent, pathMatch: 'full' },
  { path: '500', component: GenericErrorComponent, pathMatch: 'full' },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'

  }), /* TIME ZONE DATA PICKERR */
    MatDatepickerModule, MatMomentDateModule],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
