<form>
    <div mat-dialog-content class="qualificates-theme">
        <div fxFlex fxLayout="column">

            <!--<div fxFlex="100" fxLayout="row" class="options-items">
                <div fxFlex="20"  style=" padding: 10px;">
                    <mat-checkbox [checked]="testConfig.item" (change)="testConfig.item = $event?.checked; onChangeSelectionOption()">¿Desea añadir item de verificación?</mat-checkbox>
                </div>
                <div fxFlex="20" style=" padding: 10px;">
                    <mat-checkbox [checked]="testConfig.expectedResult" (change)="testConfig.expectedResult = $event?.checked; onChangeSelectionOption()">¿Desea añadir resultado esperado?</mat-checkbox>
                </div>
                <div fxFlex="20"  style=" padding: 10px;">
                    <mat-checkbox [checked]="testConfig.obtainedResult" (change)="testConfig.obtainedResult = $event?.checked; onChangeSelectionOption()">¿Desea añadir resultado obtenido?</mat-checkbox>
                </div>
                <div fxFlex="20"  style=" padding: 10px;">
                    <mat-checkbox [checked]="testConfig.probeTraceability" (change)="testConfig.probeTraceability = $event?.checked; onChangeSelectionOption()">¿Desea añadir trazabilidad de sondas?</mat-checkbox>
                </div>
                <div fxFlex="20"  style=" padding: 10px;">
                    <mat-checkbox [checked]="testConfig.testResult" (change)="testConfig.testResult = $event?.checked; onChangeSelectionOption()">¿Desea añadir resultado de ensayos?</mat-checkbox>
                </div>
                <div fxFlex="20"  style=" padding: 10px;">
                    <mat-checkbox [checked]="testConfig.testVerification" (change)="testConfig.testVerification = $event?.checked; onChangeSelectionOption()">¿Desea añadir verificación de ensayos?</mat-checkbox>
                </div>
                <div fxFlex="20"  style=" padding: 10px;">
                    <mat-checkbox [checked]="testConfig.bioTraceability" (change)="testConfig.bioTraceability = $event?.checked; onChangeSelectionOption()">¿Desea añadir trazabilidad BIO?</mat-checkbox>
                </div>
            </div>-->

            <div fxFlex="100" fxLayout="column">
                <div fxFlex="100" fxLayout="row" class="table-title">
                    <div fxFlex="100">
                        <input matInput name="title-essay" [(ngModel)]="data.data.testDescription">
                    </div>
                </div>

                <div *ngIf="testConfig?.testGeneralData">
                    <div fxLayout="column">
                        <div fxFlex="100" class="title-table-test">
                          <span>Objetivo</span>
                        </div>
                        <div fxLayout="row" *ngFor="let value of dataSource.protocolQTestGeneralData; let indexTest = index">
                          <div fxFlex="100" class="table-item" style="margin-bottom: 80px">
                            <quill-editor [(ngModel)]="value.objective" [name]="'objective' + indexTest" [id]="'objective' + indexTest" [modules]="{ toolbar: quillConfig.toolbar }" style="height: 150px; font-weight: normal;"></quill-editor>
                          </div>
                        </div>
                      </div>
                    <div fxLayout="column">
                        <div fxFlex="100" class="title-table-test">
                            <span>Metodología</span>
                        </div>
                        <div fxLayout="row" *ngFor="let value of dataSource.protocolQTestGeneralData; let indexTest = index">
                            <div fxFlex="100" class="table-item" style="margin-bottom: 80px">  
                                <quill-editor [(ngModel)]="value.methodology" [name]="'methodology' + indexTest" [id]="'methodology' + indexTest" [modules]="{ toolbar: quillConfig.toolbar }" style="height: 150px; font-weight: normal;"></quill-editor>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column">
                        <div fxFlex="100" class="title-table-test">
                            <span>Criterios de aceptación</span>
                        </div>
                        <div fxLayout="row" *ngFor="let value of dataSource.protocolQTestGeneralData; let indexTest = index">
                            <div fxFlex="100" class="table-item" style="margin-bottom: 80px">
                                <quill-editor [(ngModel)]="value.criteria" [name]="'criteria' + indexTest" [id]="'criteria' + indexTest" [modules]="{ toolbar: quillConfig.toolbar }" style="height: 150px; font-weight: normal;"></quill-editor>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div fxFlex="100" *ngFor="let item of dataSource?.protocolQTestItem; let indexItem = index"
                    fxLayout="column">
                    <div fxFlex="100">
                        <div fxFlex="15" *ngIf="testConfig.item">
                            <button class="btn-add" mat-raised-button (click)="newItemVeritication()">
                                {{ 'Nuevo item' | translate }}
                                <mat-icon fxHide.lt-sm>add</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div fxFlex="100" fxLayout="row" class="table-item">
                        <div fxFlex="50" *ngIf="testConfig?.item">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'Item verificación' | translate }}</mat-label>
                                <input matInput [name]="'item'+indexItem" [(ngModel)]="item.description">
                            </mat-form-field>
                        </div>
                        <div fxFlex="5" *ngIf="testConfig?.item" [fxHide]="indexItem === 0">
                            <button mat-icon-button (click)="deleteRowItem(indexItem)"
                                matTooltip="{{ 'button.delete' | translate }}">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                        <div fxFlex="15">
                            <button class="btn-add" mat-raised-button (click)="newDataVerification(indexItem)">
                                {{ 'Nueva columna' | translate }}
                                <mat-icon fxHide.lt-sm>add</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div *ngFor="let value of item.protocolQTestDatas; let indexValue = index">
                        <div fxFlex="100" fxLayout="row" class="table-item">
                            <div fxFlex="30">
                                <mat-form-field class="campoFormulario">
                                    <mat-label>{{ 'Verificación' | translate }}</mat-label>
                                    <textarea matInput [name]="'verificationTypeDesc'+indexItem+indexValue"
                                        [(ngModel)]="value.verificationTypeDesc" rows="5"></textarea>
                                </mat-form-field>
                            </div>

                            <div fxFlex="30" class="input-dinamyc-item" *ngIf="testConfig?.expectedResult">
                                <mat-form-field class="campoFormulario" *ngFor="let itemResult of value.expectedResultDesc; let indexEx = index"  >
                                        <mat-label>{{ 'Resultado esperado' | translate }}</mat-label>
                                        <input matInput [name]="'expectedResultDesc' + indexValue + indexEx"
                                            [ngModel]="value.expectedResultDesc[indexEx]" (blur)="value.expectedResultDesc[indexEx] = $event?.target?.value">
                                </mat-form-field>
                            </div>

                            <!--<div fxFlex="25" *ngIf="testConfig.obtainedResult">
                                <mat-form-field class="campoFormulario">
                                    <mat-label>{{ 'Resultado obtenido' | translate }}</mat-label>
                                    <input matInput
                                    [name]="'obtainedResultDesc'+indexValue" [(ngModel)]="value.obtainedResultDesc">
                                </mat-form-field>
                            </div>-->

                            <div fxFlex="30" class="input-dinamyc-item">
                                <mat-form-field class="campoFormulario">
                                    <mat-label>{{ 'Resultado' | translate }}</mat-label>
                                    <mat-select multiple [name]="'sResultDesc'+indexValue"
                                        [(ngModel)]="value.resultDesc">
                                        <mat-option *ngFor="let sel of resultQTypes" checkboxPosition="before"
                                            [value]="sel.translation">
                                            {{'protocolTest.result.'+sel.translation | translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="5" class="input-dinamyc-btn">
                                <button mat-icon-button (click)="deleteRowData(indexItem,indexValue)"
                                    matTooltip="{{ 'button.delete' | translate }}">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="testConfig?.probeTraceability">
                <div fxLayout="column">
                    <div fxFlex="100" class="title-table-test">
                        <span>Trazabilidad de las sondas</span>
                    </div>
                    <div fxFlex="100">
                        <div fxFlex="15">
                            <button class="btn-add" mat-raised-button (click)="newProbeTraceability()">
                                {{ 'Nueva sonda' | translate }}
                                <mat-icon fxHide.lt-sm>add</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div fxFlex="100" fxLayout="column">
                        <div fxFlex="100" *ngFor="let value of dataSource.protocolQTestProbeTraceability; let indexProbe = index">
                            <div fxFlex="100" style="justify-content: center;">
                                <div fxFlex="30">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'Sonda' | translate }}</mat-label>
                                        <input matInput [name]="'descriptionProbe'+indexProbe"
                                            [(ngModel)]="value.descriptionProbe">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="10"> 
                                    <button mat-icon-button (click)="deleteProbeTraceability(indexProbe)"
                                        matTooltip="{{ 'button.delete' | translate }}">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="testConfig?.bioTraceability">
                <div fxLayout="column">
                    <div fxFlex="100" class="title-table-test">
                        <span>Trazabilidad de los bioindicadores</span>
                    </div>
                    <div fxFlex="100">
                        <div fxFlex="15">
                            <button class="btn-add" mat-raised-button (click)="newBioTraceability()">
                                {{ 'Nuevo bioindicador' | translate }}
                            </button>
                        </div>
                    </div>
                    <div fxFlex="100" *ngFor="let value of dataSource.protocolQTestBioindicadores; let indexBio = index">
                        <div fxFlex="100" style="justify-content: center;">
                            <div fxFlex="30">
                                <mat-form-field class="campoFormulario">
                                    <mat-label>{{ 'Bioindicador' | translate }}</mat-label>
                                    <input matInput [name]="'descriptionBio'+indexBio"
                                        [(ngModel)]="value.bioDescription">
                                </mat-form-field>
                            </div>
                            <div fxFlex="10"> 
                                <button mat-icon-button (click)="deleteBioTraceability(indexBio)"
                                    matTooltip="{{ 'button.delete' | translate }}">
                                <mat-icon>delete</mat-icon>
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="testConfig?.testVerification">
                <div fxLayout="column">
                    <div fxFlex="100" class="title-table-test">
                        <span>Verificación de Pruebas</span>
                    </div>
                    <div fxLayout="row" class="table-item">
                        <div fxFlex="15">
                            <button class="btn-add" mat-raised-button (click)="newTestVerification()">
                                {{ 'Nueva prueba' | translate }}
                                <mat-icon fxHide.lt-sm>add</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div fxLayout="row" *ngFor="let value of dataSource.protocolQTestVerification; let indexTest = index">
                        <div fxFlex="30" class="table-item " class="input-dinamyc-item">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'Prueba' | translate }}</mat-label>
                                <input matInput [name]="'test' + indexTest" [(ngModel)]="value.test">
                            </mat-form-field>
                        </div>
                        <div fxFlex="30" class="table-item">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'Descripcion' | translate }}</mat-label>
                                <textarea matInput [name]="'descriptionTest' + indexTest" [(ngModel)]="value.descriptionTest" rows="5"></textarea>
                            </mat-form-field>
                        </div>
                        <div fxFlex="30" class="table-item" class="input-dinamyc-item">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'Resultado' | translate }}</mat-label>
                                <mat-select multiple [name]="'vResultVerification'+indexTest" [(ngModel)]="value.resultDesc">
                                    <mat-option *ngFor="let sel of resultQTypes" checkboxPosition="before" [value]="sel.translation">
                                        {{'protocolTest.result.'+sel.translation | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="5" class="input-dinamyc-btn">
                            <button mat-icon-button (click)="deleteTestVerification(indexTest)"
                                matTooltip="{{ 'button.delete' | translate }}">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div fxFlex="100" fxLayout="column" *ngIf="testConfig.testResult">
                <div fxLayout="column">
                    <div fxFlex="100" class="title-table-test">
                        <span>Resultado de ensayos</span>
                    </div>
                    <div fxFlex="100" fxLayout="row" class="table-item">
                        <div fxFlex="15">
                            <button class="btn-add" mat-raised-button (click)="newDataResult()">
                                {{ 'Nueva columna' | translate }}
                                <mat-icon fxHide.lt-sm>add</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div *ngFor="let value of dataSource.protocolQTestResults; let indexValue = index">
                        <div fxFlex="100" fxLayout="row" class="table-item">
                            <div fxFlex="30">
                                <mat-form-field class="campoFormulario">
                                    <mat-label>{{ 'Verificación' | translate }}</mat-label>
                                    <textarea matInput [name]="'verificationTypeDesc'+indexValue"
                                        [(ngModel)]="value.verification" rows="5"></textarea>
                                </mat-form-field>
                            </div>

                            <div fxFlex="30" class="input-dinamyc-item">
                                <mat-form-field class="campoFormulario"
                                    *ngFor="let itemResult of value.resultExpected; let indexEx = index">
                                    <mat-label>{{ 'Resultado esperado' | translate }}</mat-label>
                                    <input matInput [name]="'resultExpected'+indexValue+indexEx"
                                        [ngModel]="value.resultExpected[indexEx]" (blur)="value.resultExpected[indexEx] = $event?.target?.value">
                                </mat-form-field>
                            </div>

                            <div fxFlex="30" class="input-dinamyc-item">
                                <mat-form-field class="campoFormulario">
                                    <mat-label>{{ 'Resultado' | translate }}</mat-label>
                                    <mat-select multiple [name]="'sResult'+indexValue"
                                        [(ngModel)]="value.result">
                                        <mat-option *ngFor="let sel of resultQTypes" checkboxPosition="before"
                                            [value]="sel.translation">
                                            {{'protocolTest.result.'+sel.translation | translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="5" class="input-dinamyc-btn">
                                <button mat-icon-button (click)="deleteRowDataResult(indexValue)"
                                    matTooltip="{{ 'button.delete' | translate }}">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>     
            <div fxFlex="15">
                <button class="btn-add" mat-raised-button color="primary" (click)="fileInput.click()">
                    {{ 'protocolTest.test.image' | translate }}
                    <mat-icon fxHide.lt-sm>add</mat-icon>
                </button>
                <input type="file" #fileInput (change)="onImageSelected($event)" style="display: none;">
            </div>
         </div>
    </div>

    <div mat-dialog-actions class="qualificates-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>
</form>