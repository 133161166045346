import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ReferenceDocProtocol} from 'src/app/model/referenceDocProtocol';
import { ProtocolsQDataSource } from 'src/app/model/protocolQDataSource';
import { ProtocolQService } from 'src/app/services/protocolQ.service';
import { SpinnerService } from 'src/app/services/spinner.service';

export interface DialogDataReferenceProtocolListConfig {
  isList: boolean;
}

@Component({
  selector: 'app-reference-doc-protocol-list',
  templateUrl: './referenceDocProtocol-list.component.html'
})
export class ReferenceDocProtocolListComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: ProtocolsQDataSource;
  
  displayedColumns = ['code', 'name', 'edit', 'delete'];

  private destroy$ = new Subject<void>();

  constructor(
    private protocolService: ProtocolQService,
    private router: Router,
    private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataReferenceProtocolListConfig
  ) { }

  ngOnInit(): void {
    this.loadReferenceDocProtocolList();
  }

  loadReferenceDocProtocolList(): void {
    // Aquí deberías implementar la lógica para cargar la lista de documentos de referencia
  }

  saveSearchFilter(execution: ReferenceDocProtocol): void {
    localStorage.setItem('filter', JSON.stringify(execution));
  }

  editRow(id: number): void {
    void this.router.navigateByUrl(`/qualificates/protocol?id=${id}`);
  }

}
