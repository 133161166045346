
<mat-tab-group #protocolTabs (selectedTabChange)="onTabChange($event)">
    <mat-tab #protocolTab>

        <ng-template mat-tab-label>{{ 'protocolEdit.general.title' | translate }} </ng-template>

        <form [formGroup]="form">

            <mat-card class="card" *ngIf="protocol.id != null">
                <mat-card-content>
                    <div fxFlex="100" style="text-align: end;">
                        <div fxFlex="100">
                            <button mat-raised-button color="primary" (click)="showAdjustTemplate()">{{ 'button.adjustTemplate' | translate }}</button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="card">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'protocolEdit.general.title' | translate }}
                    </mat-toolbar>

                    <div fxLayout="row" class="flexwrap">

                        <div fxFlex="50" fxFlex.lt-md="100%">
                            <div fxLayout="column">
                                <div fxLayout="row" class="flexwrap">
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <div fxLayout="row" class="flexwrap">
                                            <div fxFlex="50" fxFlex.lt-md="100%">
                                                <div fxLayout="row" class="flexwrap">
                                                    <div fxFlex>
                                                        <mat-form-field>
                                                            <input matInput formControlName="documentCode" required
                                                                [(ngModel)]="protocol.documentCode"
                                                                [errorStateMatcher]="matcher"
                                                                placeholder="{{ 'protocolEdit.general.documentCode.label' | translate }}">
                                                            <mat-error>
                                                                {{ 'protocolEdit.general.documentCode.error' | translate
                                                                }}
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div fxFlex="40px" *ngIf="canEditDocumentCode()">
                                                        <button mat-icon-button (click)="editDocumentCode()">
                                                            <mat-icon>mode_edit</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div fxFlex="50" fxFlex.lt-md="100%">
                                                <mat-form-field class="campoFormulario">
                                                    <input matInput formControlName="clientDocumentCode"
                                                        [(ngModel)]="protocol.clientDocumentCode"
                                                        [errorStateMatcher]="matcher"
                                                        placeholder="{{ 'protocolEdit.general.clientDocumentCode.label' | translate }}">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>

                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'protocolEdit.general.client.label' | translate }}</mat-label>
                                            <mat-select #clientSelect required formControlName="idClient"
                                                [(ngModel)]="protocol.idClient"
                                                [disabled]="+protocol.signedVersion > 0">
                                                <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'"
                                                    [array]="clients" (filteredReturn)="clientsFiltered =$event"
                                                    [noResultsMessage]="'common.noResults' | translate"
                                                    [placeholder]="'common.selectFilterPlaceholder' | translate">
                                                </mat-select-filter>
                                                <mat-option *ngFor="let client of clientsFiltered" [value]="client.id">
                                                    {{ client.name }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'protocolEdit.general.client.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'protocolEdit.general.process.label' | translate }}
                                            </mat-label>
                                            <mat-select required formControlName="idProcess"
                                                [(ngModel)]="protocol.idProcess">
                                                <mat-option>--</mat-option>
                                                <mat-option *ngFor="let item of processes" [value]="item.id">
                                                    {{ 'processType.' + item.translation | translate }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'protocolEdit.general.process.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput formControlName="projectNo" [(ngModel)]="protocol.projectNo"
                                                required [errorStateMatcher]="matcher"
                                                placeholder="{{ 'protocolEdit.general.projectNo.label' | translate }}">
                                            <mat-error>
                                                {{ 'protocolEdit.general.projectNo.label' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'protocolEdit.general.projectManager.label' | translate }}
                                            </mat-label>
                                            <mat-select formControlName="projectManager"
                                                [(ngModel)]="protocol.usernameProjectManager">
                                                <mat-option>--</mat-option>
                                                <mat-option *ngFor="let item of respField" [value]="item.username">
                                                    {{ item.fullName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div fxFlex="50" fxFlex.lt-md="100%">
                            <div fxLayout="column">

                                <div fxLayout="row" class="flexwrap">
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput formControlName="regUser" [(ngModel)]="protocol.regUser"
                                                placeholder="{{ 'protocolEdit.general.regUser.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput formControlName="modUser" [(ngModel)]="protocol.modUser"
                                                placeholder="{{ 'protocolEdit.general.modUser.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div fxLayout="row" class="flexwrap">
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput formControlName="regFc" [ngModel]="protocol.regFc | fixDate"
                                                placeholder="{{ 'protocolEdit.general.regFc.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput formControlName="modFc" [ngModel]="protocol.modFc | fixDate"
                                                placeholder="{{ 'protocolEdit.general.modFc.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div fxLayout="row" class="flexwrap">
                                    <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="protocol.statusTranslation != null">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="status" disabled
                                                placeholder="{{ 'protocolEdit.general.status.label' | translate }}"
                                                value="{{ 'protocolStatus.' + protocol.statusTranslation | translate }}">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50" fxFlex.lt-md="100%"
                                        *ngIf="protocol.currentVersion != '0' && protocol.currentVersion != null">
                                        <div fxLayout="row">
                                            <div fxFlex="50">
                                                <mat-form-field class="campoFormulario">
                                                    <input matInput formControlName="currentVersion"
                                                        [ngModel]="protocol.currentVersion"
                                                        placeholder="{{ 'protocolEdit.general.currentVersion.label' | translate }}">
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="50">
                                                <mat-form-field class="campoFormulario">
                                                    <input matInput formControlName="signedVersion"
                                                        [ngModel]="protocol.signedVersion"
                                                        placeholder="{{ 'protocolEdit.general.signedVersion.label' | translate }}">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    
                </mat-card-content>
            </mat-card>

            <mat-card class="card">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'protocolEdit.referenceDocs.title' | translate }}

                        <button mat-icon-button color="accent" (click)="openNewRefDoc()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </mat-toolbar>

                    <br />

                    <div fxLayout="row">

                        <div fxFlex fxFlex.lt-md="100%">

                            <table mat-table matSort #refDocTable [dataSource]="protocol.referenceDocs"
                             style="width: 95%;">

                                <!-- code Column -->
                                <ng-container matColumnDef="code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{ 'protocolEdit.referenceDocs.code.label' | translate }}
                                    </th>
                                    <td mat-cell *matCellDef="let item"> {{ item.code }} </td>
                                </ng-container>

                                <!-- name Column -->
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{ 'protocolEdit.referenceDocs.name.label' | translate }}
                                    </th>
                                    <td mat-cell *matCellDef="let item" style="white-space: pre;"> {{ item.name }} </td>
                                </ng-container>

                                <!-- Button edit Column -->
                                <ng-container matColumnDef="edit">
                                    <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                                    <td mat-cell *matCellDef="let element; let refDocIndex = index">
                                        <button mat-icon-button (click)="editRefDoc(refDocIndex)">
                                            <mat-icon>mode_edit</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <!-- Button delete Column -->
                                <ng-container matColumnDef="delete">
                                    <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                                    <td mat-cell *matCellDef="let element; let refDocIndex = index">
                                        <button mat-icon-button (click)="removeRefDoc(refDocIndex)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColsRefDoc"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColsRefDoc;"></tr>

                            </table>

                        </div>

                    </div>

                </mat-card-content>

            </mat-card>
            <mat-card class="card">
                <mat-card-content>
                    <br />

                    <mat-toolbar color="secondary">
                        {{ 'protocolEdit.equipment.title' | translate }}

                        <button mat-icon-button color="accent" (click)="openDialogEquipment()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </mat-toolbar>

                    <!-- equipos area -->
                    <mat-accordion>
                        <mat-expansion-panel class="overflowX"
                            *ngFor="let equipment of protocol.equipments; let indexEquipment = index"
                            (afterExpand)="equipmentTab = indexEquipment" (closed)="equipmentTab = null">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div style="margin: auto 0;display: block;">
                                        {{ equipment.equipment }}
                                        -
                                        {{ equipment.model }}({{ equipment.serialNumber }})
                                    </div>
                                    <button mat-icon-button color="accent"
                                        *ngIf="equipmentTab === indexEquipment"
                                        (click)="openDialogEditEquipment(indexEquipment)">
                                        <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                                    </button>

                                    <button mat-icon-button color="accent"
                                        *ngIf="equipmentTab === indexEquipment"
                                        (click)="deleteEquipment(indexEquipment)">
                                        <mat-icon style="font-size: 20px;">delete</mat-icon>
                                    </button>
                                    <button mat-icon-button color="accent"
                                        *ngIf="equipmentTab === indexEquipment && false"
                                        (click)="duplicateEquipment(indexEquipment)">
                                    </button>
                                   <!--<button mat-icon-button color="accent"
                                        *ngIf="equipmentTab === indexEquipment"
                                        (click)="openDialogEssayEquipment(indexEquipment)">
                                        <mat-icon style="font-size: 20px;">add</mat-icon>
                                    </button>-->

                                </mat-panel-title>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-card-content>
            </mat-card>
            <mat-card class="card" *ngIf="protocol.id != null">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'protocolEdit.testQualification.title' | translate }}
                    </mat-toolbar>

                    <div fxLayout="column" class="flexwrap" *ngFor="let test of testQualificationTypeList; let indexTest = index">
                       <div fxLayout="column">
                            <div fxFlex="100" style="padding: 15px 0;">
                                <span style="font-weight: 600;">{{'testQualification.title.' + test.qualificationType | translate}}</span>
                            </div>
                            <div fxFlex="100" *ngFor="let item of test.testQTypes" style="padding: 10px 0;">
                                <mat-checkbox [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}" (change)="onSelectedItem(item, indexTest, $event)">
                                    <span>
                                        {{'testQualification.'+item.translation | translate}}
                                    </span>
                                    <button mat-raised-button *ngIf="item.checked" (click)="createTestsSelected(item)" style="box-shadow: none !important">
                                        <mat-icon fxHide.lt-sm>display_settings</mat-icon>
                                    </button>
                                </mat-checkbox>
                            </div>
                       </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="card">
                <mat-card-actions>
                    <div fxFlex></div>
                    <button mat-raised-button color="primary" *ngIf="showRevertSign()" (click)="revertSign()">
                        {{ 'button.revertSign' | translate }} </button>
                        <button mat-raised-button color="primary" (click)="saveProtocol()">{{ 'button.save' | translate }}</button>
                    <div fxFlex="5px"></div>
                    <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                        >
                        <button mat-raised-button color="primary" *ngIf="showManualSign()" (click)="manualSign()">
                            {{ 'button.sign-manual' | translate }}
                        </button>
                    </div>
                    <div fxFlex="5px"></div>
                        <button mat-raised-button color="primary" *ngIf="showAutomaticSign()" (click)="prepareToSign()">
                            {{ 'button.sign-automatic' | translate }}
                        </button>
                    <div fxFlex="5px"></div>
                        <button mat-raised-button color="accent" *ngIf="protocol.documentCode != null"
                            (click)="downloadPdf()">{{ 'button.generateDoc' | translate }}
                            </button>
                    <div fxFlex="5px"></div>
                    <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel' | translate
                        }}</button>
                </mat-card-actions>
            </mat-card>
        </form>
    </mat-tab>
    <mat-tab #auditTab label="{{ 'protocolEdit.historical.title' | translate }}" *ngIf="protocol.id != null">
        <app-protocol-q-edit-audit [idProtocol]="protocol.id" [documentCode]="protocol.documentCode">
        </app-protocol-q-edit-audit>
    </mat-tab>
</mat-tab-group>