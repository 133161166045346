import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ProtocolQ, ProtocolQFilter } from 'src/app/model/protocol';
import { ProtocolsQDataSource } from 'src/app/model/protocolQDataSource';
import { ProtocolQService } from 'src/app/services/protocolQ.service';
import { SpinnerService } from 'src/app/services/spinner.service';

export interface DialogDataProtocolListConfig {
  isList: boolean;
}

@Component({
  selector: 'app-protocol-list',
  templateUrl: './protocol-list.component.html'
})
export class ProtocolQListComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  filter: ProtocolQFilter = new ProtocolQFilter();
  hasFiltered = false;
  dataSource: ProtocolsQDataSource;

  displayedColumns = ['documentCode', 'projectNo', 'client', 'status', 'realizationDate', 'download', 'edit', 'delete'];

  private destroy$ = new Subject<void>();

  constructor(
    private protocolService: ProtocolQService,
    private router: Router,
    private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProtocolListConfig
  ) {
    if (data.isList == null) {
      data.isList = true;
    }
  }

  ngOnInit(): void {
    this.loadProtocolList();

  }

  doFilter(event: ProtocolQFilter): void {
    this.filter = event;

    this.hasFiltered = true;

    this.loadProtocolList();
  }

  loadProtocolList(): void {
    this.dataSource = new ProtocolsQDataSource(this.protocolService);

    this.filter.sortBy = this.sort.active || 'documentCode';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator?.pageIndex || 0;
    this.filter.pageSize = this.paginator?.pageSize || 10;
    if (this.hasFiltered) {
      this.saveSearchFilter(this.filter);
      this.dataSource.loadProtocols(this.filter);
    }
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  saveSearchFilter(execution: ProtocolQFilter): void {
    localStorage.setItem('filter', JSON.stringify(execution));
  }

  canClone(ex: ProtocolQ): boolean {
    return false;
  }

  editRow(id: number): void {
    void this.router.navigateByUrl(`/qualificates/protocol?id=${id}`);
  }

  new(): void {
    void this.router.navigateByUrl('/qualificates/protocol?id=0');
  }
}
