/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TestQConfig } from '../../../../model/testQConfig';
import { TestQType, TestTypeEnum } from '../../../../model/testQType';
import { TestQTypeService } from '../../../../services/testQType.service';
import { Subject, takeUntil } from 'rxjs';
import { TestQElementTypeService } from '../../../../services/testQElementType.service';
import { TestQElementType } from '../../../../model/testQElementType';
import { VerificationQTypeService } from '../../../../services/verificationQType.service';
import { VerificationQType } from '../../../../model/verificationQType';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ExpectedResultTypeService } from '../../../../services/expectedResultType.service';
import { ExpectedResultType } from 'src/app/model/expectedResultType';
import { ResultQTypeService } from '../../../../services/resultQType.service';
import { ResultQType } from '../../../../model/resultQType';
import { SnackBarService } from '../../../../services/snackBar.service';
import { TestQConfigService } from '../../../../services/testQConfig.service';
import { TestQDataVerificationService } from '../../../../services/testQDataVerification.service';
import { CustomDatepickerHeaderComponent } from '../../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { ProtocolQTestGeneralData, ProtocolQTest,
	ProtocolQTestBioindicadores,
	ProtocolQTestData,
	ProtocolQTestDataOQ,
	ProtocolQTestItem,
	ProtocolQTestProbeTraceability,
	ProtocolQTestResults, 
	ProtocolStatus} from '../../../../model/protocol';

import { TestQItemService } from '../../../../services/testQItem.service';
import { TestQItem } from '../../../../model/testQItem';
import { TranslateService } from '@ngx-translate/core';

import { TestQResultsService } from '../../../../services/testQResults.service';
import { TestQResults } from '../../../../model/testQResults';

import { TestQDataOQService } from '../../../../services/testQDataOQ.service';
import { TestQDataOQ } from '../../../../model/testQDataOQ';
import { TestGeneralDataConfig } from 'src/app/model/testGeneralDataConfig';
import { TestGeneralDataConfigService } from 'src/app/services/testGeneralDataConfig.service';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { ReasonDialogComponent } from 'src/app/components/shared/reason-dialog/reason-dialog.component';

export class Group {
	level = 0;
	expanded = false;
	totalCounts = 0;
}

export interface DialogQEditTest {
	idItem: number;
	testQType: TestQType;
	reason: string;
	isEdit: boolean;
	isEditTest: boolean;
	data: ProtocolQTest;
	idStatus: number;
}

@Component({
	selector: 'app-protocol-q-edit-test',
	templateUrl: './protocol-q-edit-test.component.html',
	styleUrls: ['./protocol-q-edit-test.component.scss']
})
export class ProtocolQEditTestComponent implements OnInit {

	@ViewChild(MatSort) sort: MatSort;
	@ViewChild('tableTest', { static: true }) tableTest: MatTable<any>;

	allData: any[];
	_allGroup: any[];
	dataSource: ProtocolQTest = new ProtocolQTest();

	displayedColumns: string[] = ['verificationTypeDesc'];
	columnsToDisplay: string[] = this.displayedColumns.slice();
	groupByColumns: string[] = [];

	testConfig: TestQConfig = new TestQConfig();
	testQTypes: TestQType[];
	testQElementTypes: TestQElementType[];
	verificationQTypes: VerificationQType[];
	expectedResultTypes: ExpectedResultType[];
	resultQTypes: ResultQType[];
	columns: any[] = [];
	expandedCar: any[] = [];

	currentUser: User;
	customDatepickerHeader = CustomDatepickerHeaderComponent;

	quillConfig = {
		toolbar: [
		  ['bold', 'italic', 'underline'],
		  ['image'],
		  [{ list: 'ordered'}, { list: 'bullet' }]
		]
	  };

	private destroy$ = new Subject<void>();

	constructor(private testQTypeService: TestQTypeService,
		private testQElementTypeService: TestQElementTypeService,
		private verificationQTypeService: VerificationQTypeService,
		private expectedResultTypeService: ExpectedResultTypeService,
		private resultQTypeService: ResultQTypeService,
		private snackBarService: SnackBarService,
		private testQConfigService: TestQConfigService,
		private testQResultsService: TestQResultsService,
		private testQDataVerificationService: TestQDataVerificationService,
		private testGeneralDataConfigService: TestGeneralDataConfigService,
		private testQDataOQService: TestQDataOQService,
		private testQItemService: TestQItemService,
		private dialogRef: MatDialogRef<ProtocolQEditTestComponent>,
		private translate: TranslateService,
		private userService: UserService,			
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: DialogQEditTest) { 
			this.currentUser = this.userService.currentProfile;
		}		

	ngOnInit(): void {
		if (!this.data.data) {
			this.data.data = new ProtocolQTest();
		} else {
			this.dataSource = this.data.data;
		}		
		this.data.data.testDescription = this.translate.instant('testQualification.' + this.data.testQType.translation) as string;

		this.testQTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: TestQType[]) => {
			this.testQTypes = result;
		});

		this.testQElementTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: TestQElementType[]) => {
			this.testQElementTypes = result;
		});

		this.verificationQTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: VerificationQType[]) => {
			this.verificationQTypes = result;
		});

		this.expectedResultTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: ExpectedResultType[]) => {
			this.expectedResultTypes = result;
		});

		this.resultQTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: ResultQType[]) => {
			this.resultQTypes = result;
		});

		if (this.data.testQType) {
			this.testQConfigService.findByIdTest(this.data.testQType.id).pipe(takeUntil(this.destroy$)).subscribe((result: TestQConfig) => {
				this.testConfig = result;
			});

			if (!this.data.data.protocolQTestItem) {
				// eslint-disable-next-line max-len
				this.testQItemService.findAllByIdTest(this.data.testQType.id).pipe(takeUntil(this.destroy$)).subscribe((result: TestQItem[]) => {
					result?.forEach(r => {
						const protocolQTestItem = new ProtocolQTestItem();
						protocolQTestItem.description = r.description;
						protocolQTestItem.idType = r.idType;
						protocolQTestItem.protocolQTestDatas = [];
						r.testQDataVerificationDTO?.forEach(rt => {
							const protocolQTestData = new ProtocolQTestData();
							protocolQTestData.idItem = r.id;
							protocolQTestData.verificationTypeDesc = rt.verificationTypeDesc;
							protocolQTestData.obtainedResultDesc = rt.obtainedResultDesc;
							protocolQTestData.expectedResultDesc = rt.expectedResultDesc;
							protocolQTestData.resultDesc = rt.resultDesc;
							protocolQTestItem.protocolQTestDatas.push(protocolQTestData);
						});

						if (!this.dataSource?.protocolQTestItem) {
							this.dataSource.protocolQTestItem = [];
						}
						this.dataSource?.protocolQTestItem.push(protocolQTestItem);
					})
				});

			} else {
				this.dataSource.protocolQTestItem = this.data.data.protocolQTestItem;
			}

			if(!this.data.data.protocolQTestVerification){
				// eslint-disable-next-line max-len
				this.testQDataOQService.findTestQDataOQByIdTest(this.data.testQType.id).pipe(takeUntil(this.destroy$)).subscribe((result: TestQDataOQ[])=> {
					result?.forEach(r => {
						const protocolQTestOQ = new ProtocolQTestDataOQ();
						protocolQTestOQ.descriptionTest = r.descriptionTest;
						protocolQTestOQ.test = r.test;
						protocolQTestOQ.resultDesc = r.resultDesc;

						if(!this.dataSource.protocolQTestVerification){
							this.dataSource.protocolQTestVerification = [];
						}
						this.dataSource?.protocolQTestVerification.push(protocolQTestOQ);
					});
				})
			}else{
				this.dataSource.protocolQTestVerification = this.data.data.protocolQTestVerification;
			}

			if(!this.data.data.protocolQTestGeneralData){
				this.testGeneralDataConfigService.getTestGeneralDataConfigById(this.data.testQType.id).pipe(takeUntil(this.destroy$)).subscribe((result: TestGeneralDataConfig[])=>{
					result?.forEach(r=>{
						const protocolGeneral = new ProtocolQTestGeneralData();
						protocolGeneral.methodology = r.methodology;
						protocolGeneral.objective = r.objective;
						protocolGeneral.criteria = r.criteria;

						if(!this.dataSource.protocolQTestGeneralData){
							this.dataSource.protocolQTestGeneralData = [];
						}
						this.dataSource?.protocolQTestGeneralData.push(protocolGeneral)
					});

				})
			}else{
				this.dataSource.protocolQTestGeneralData = this.data.data.protocolQTestGeneralData;
			}

			if (this.data.data.protocolQTestProbeTraceability) {
				this.dataSource.protocolQTestProbeTraceability = this.data.data.protocolQTestProbeTraceability;
			}

			if (this.data.data.protocolQTestBioindicadores) {
				this.dataSource.protocolQTestBioindicadores = this.data.data.protocolQTestBioindicadores;
			}

			if (!this.data.data.protocolQTestResults) {
				// eslint-disable-next-line max-len
				this.testQResultsService.findByIdTest(this.data.testQType.id).pipe(takeUntil(this.destroy$)).subscribe((result: TestQResults[]) => {
					result.forEach(rt => {
						const protocolQTestResult = new ProtocolQTestResults();
						protocolQTestResult.verification = rt.verification;
						protocolQTestResult.resultExpected = rt.resultExpected;
						protocolQTestResult.result = rt.result;
						if (!this.dataSource?.protocolQTestResults) {
							this.dataSource.protocolQTestResults = [];
						}
						this.dataSource?.protocolQTestResults.push(protocolQTestResult);
					})
				});
			} else {
				this.dataSource.protocolQTestResults = this.data.data.protocolQTestResults;

			}
		}

		this.onChangeSelectionOption();
	}

	onChangeSelectionOption(): void {
		this.displayedColumns = [];
		this.displayedColumns.push('verificationTypeDesc');
		if (this.testConfig.expectedResult) {
			this.displayedColumns.push('expectedResult')
		}

		if (this.testConfig.obtainedResult) {
			this.displayedColumns.push('obtainedResult')
		}

		if (this.testConfig.testResult) {
			this.displayedColumns.push('testResult')
		}

		this.columnsToDisplay = this.displayedColumns.slice();
	}

	deleteRowData(indexItem: number, itemData: number): void {
		if (this.dataSource.protocolQTestItem[indexItem]) {
			this.dataSource.protocolQTestItem[indexItem].protocolQTestDatas.splice(itemData, 1)
		}
	}

	deleteRowItem(indexItem: number): void {
			this.dataSource.protocolQTestItem.splice(indexItem, 1)
	}

	onOkClick(): void {
		const errs = [];

		if (this.testConfig.expectedResult) {
			if (!this.dataSource.protocolQTestItem) {
				errs.push(this.translate.instant('protocolTest.probe.add.error'));
			} else {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/no-unsafe-member-access
				for (let index = 0; index < this.dataSource.protocolQTestItem.length; index++) {
					if (!this.dataSource.protocolQTestItem[index] && this.testConfig.item) {
						errs.push(this.translate.instant('protocolTest.verificationType.item.description.error'));
					} else {
						if (!this.dataSource.protocolQTestItem[index].description) {
							errs.push(this.translate.instant('protocolTest.verificationType.item.error'));
						}
						// eslint-disable-next-line @typescript-eslint/prefer-for-of, max-len
						for (let indexData = 0; indexData < this.dataSource.protocolQTestItem[index].protocolQTestDatas.length; indexData++) {
							if (!this.dataSource.protocolQTestItem[index].protocolQTestDatas[indexData]) {
								errs.push(this.translate.instant('protocolTest.verificationType.add.error'));
							} else {
								if (!this.dataSource.protocolQTestItem[index].protocolQTestDatas[indexData].verificationTypeDesc) {
									errs.push(this.translate.instant('protocolTest.verificationType.descripction.error'));
								}

								// eslint-disable-next-line max-len
								if (!this.dataSource.protocolQTestItem[index].protocolQTestDatas[indexData].expectedResultDesc &&
									(this.data.testQType.id !== TestTypeEnum.VERIFICATION_DOCUMENTATION_OPERATION_MAINTENANCE_CLEANING && this.data.testQType.id !== TestTypeEnum.TEST_BOWIE_DICK)) {
									errs.push(this.translate.instant('protocolTest.verificationType.expectedResultDesc.error'));
								}else {
									if(this.data.testQType.id !== TestTypeEnum.VERIFICATION_DOCUMENTATION_OPERATION_MAINTENANCE_CLEANING && this.data.testQType.id !== TestTypeEnum.TEST_BOWIE_DICK){
										const valueTestData = this.dataSource.protocolQTestItem[index].protocolQTestDatas[indexData].expectedResultDesc?.filter(e =>  e === '');

										if (valueTestData != null && valueTestData?.length > 0) {
											errs.push(this.translate.instant('protocolTest.verificationType.expectedResultDesc.error'));
										}
									}
								}

								// eslint-disable-next-line max-len
								if (!this.dataSource.protocolQTestItem[index].protocolQTestDatas[indexData].resultDesc || this.dataSource.protocolQTestItem[index].protocolQTestDatas[indexData].resultDesc?.length === 0) {
									errs.push(this.translate.instant('protocolTest.verificationType.resultDesc.error'));
								}
							}
						}
					}
				}
			}
		}

		if (this.testConfig.probeTraceability) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (!this.dataSource.protocolQTestProbeTraceability || this.dataSource.protocolQTestProbeTraceability.length === 0) {
				errs.push(this.translate.instant('protocolTest.probe.add.error'));
			} else {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/no-unsafe-member-access
				for (let index = 0; index < this.dataSource.protocolQTestProbeTraceability.length; index++) {
					if (!this.dataSource.protocolQTestProbeTraceability[index].descriptionProbe) {
						errs.push(this.translate.instant('protocolTest.probe.descripction.error'));
					}
				}
			}
		}

		if (this.testConfig.testResult) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (!this.dataSource.protocolQTestResults || this.dataSource.protocolQTestResults.length === 0) {
				errs.push(this.translate.instant('protocolTest.probe.add.error'));
			} else {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/no-unsafe-member-access
				for (let index = 0; index < this.dataSource.protocolQTestResults.length; index++) {
					if (!this.dataSource.protocolQTestResults[index].resultExpected) {
						errs.push(this.translate.instant('protocolTest.test.result.resultExpected.error'));
					} else {
						const valueTestData = this.dataSource.protocolQTestResults[index].resultExpected?.filter(e => e === '');
						if (valueTestData != null && valueTestData?.length > 0) {
							errs.push(this.translate.instant('protocolTest.verificationType.expectedResultDesc.error'));
						}
					}
					if (!this.dataSource.protocolQTestResults[index].result || this.dataSource.protocolQTestResults[index].result?.length === 0) {
						errs.push(this.translate.instant('protocolTest.test.result.result.error'));
					}
					if (!this.dataSource.protocolQTestResults[index].verification) {
						errs.push(this.translate.instant('protocolTest.test.result.verification.error'));
					}
				}
			}
		}

		if (this.testConfig.bioTraceability) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (!this.dataSource.protocolQTestBioindicadores || this.dataSource.protocolQTestBioindicadores.length === 0) {
				errs.push(this.translate.instant('protocolTest.bioindicador.add.error'));
			} else {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/no-unsafe-member-access
				for (let index = 0; index < this.dataSource.protocolQTestBioindicadores.length; index++) {
					if (!this.dataSource.protocolQTestBioindicadores[index].bioDescription) {
						errs.push(this.translate.instant('protocolTest.bioindicador.description.error'));
					}
				}
			}
		}

		if(this.testConfig.testVerification){
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if(!this.dataSource.protocolQTestVerification || this.dataSource.protocolQTestVerification.length === 0){
				errs.push(this.translate.instant('protocolTest.testVerification.add.error'));
			}else{
				// eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/no-unsafe-member-access
				for(let index = 0; index < this.dataSource.protocolQTestVerification.length; index ++){
					if (!this.dataSource.protocolQTestVerification[index].test) {
						errs.push(this.translate.instant('protocolTest.testVerification.test.error'));
					}
					if (!this.dataSource.protocolQTestVerification[index].descriptionTest) {
						errs.push(this.translate.instant('protocolTest.testVerification.description.test.error'));
					}
					if (!this.dataSource.protocolQTestVerification[index].resultDesc || this.dataSource.protocolQTestVerification[index].resultDesc?.length === 0) {
						errs.push(this.translate.instant('protocolTest.testVerification.result.erro'));
					}
				}

			}
		}		
		
		if (errs.length === 0) {
			if(!this.dataSource.regUser){
				this.dataSource.regUser = this.currentUser.username;
				this.dataSource.regDate = new Date();
			}
							
			this.dataSource.modUser = this.currentUser.username;
			this.dataSource.modDate = new Date();

			if (this.data.idStatus === ProtocolStatus.FIRMADO) {		
					
				this.requestReason((reason) => {
					this.dataSource.reason = reason;
					
					this.dataSource.reason = this.data.reason;
					this.dialogRef.close(this.dataSource);
			    });	
			} else {
				this.dialogRef.close(this.dataSource);	
			}
			
		} else {
			const error = errs.join('\n');
			this.snackBarService.sendError(error);
		}
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	newItemVeritication(): void {
		const protocolQTestItem = new ProtocolQTestItem();
		protocolQTestItem.idType = this.data.testQType.id;
		this.dataSource.protocolQTestItem.push(protocolQTestItem);
	}

	newDataVerification(index: number): void {
		
		if (!this.dataSource.protocolQTestItem[index].protocolQTestDatas) {
			this.dataSource.protocolQTestItem[index].protocolQTestDatas = [];
		}

		const protocolQTestData = new ProtocolQTestData();

       	protocolQTestData.expectedResultDesc = [];
    	
		protocolQTestData.expectedResultDesc.push('');
		this.dataSource.protocolQTestItem[index].protocolQTestDatas.push(protocolQTestData);
		
	}

	newProbeTraceability(): void {

		if (!this.dataSource.protocolQTestProbeTraceability){
			this.dataSource.protocolQTestProbeTraceability = [];
		}
		const probe = new ProtocolQTestProbeTraceability();
		this.dataSource.protocolQTestProbeTraceability.push(probe);
	}


	deleteProbeTraceability(itemData: number): void {
		this.dataSource.protocolQTestProbeTraceability.splice(itemData, 1)
	}

	newDataResult(): void {
		const protocolResult = new ProtocolQTestResults();
		if (!this.dataSource?.protocolQTestResults) {
			this.dataSource.protocolQTestResults=[];
		}
		protocolResult.resultExpected = [];
		protocolResult.resultExpected.push('');
		this.dataSource.protocolQTestResults.push(protocolResult);
	}

	deleteRowDataResult(itemData: number): void {
		this.dataSource.protocolQTestResults.splice(itemData, 1)
	}

	newBioTraceability(): void {
		if (!this.dataSource.protocolQTestBioindicadores){
			this.dataSource.protocolQTestBioindicadores = [];
		}
		const bio = new ProtocolQTestBioindicadores();
		bio.protocolTestId = this.data.testQType.id;
		this.dataSource.protocolQTestBioindicadores.push(bio);
	}

	deleteBioTraceability(itemData: number): void {
		this.dataSource.protocolQTestBioindicadores.splice(itemData, 1)
	}

	newTestVerification(): void{
		if (!this.dataSource.protocolQTestVerification){
			this.dataSource.protocolQTestVerification = [];
		}
		const test = new ProtocolQTestDataOQ();
		test.idProtocol = this.data.testQType.id;
		this.dataSource.protocolQTestVerification.push(test);

	}
	deleteTestVerification(itemData: number): void{
		this.dataSource.protocolQTestVerification.splice(itemData, 1)
	}	
	
	private requestReason(callback: (reason: string) => void) {
		const dialogRef = this.dialog.open(ReasonDialogComponent, {
		  minWidth: '40%',
		  maxHeight: '95vh',
		  data: {}
		});
	
		dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
		  if (result != null) {
			callback(result);
		  }
		});
	  }
	  onImageSelected(event: Event) {
		const input = event.target as HTMLInputElement;
		if (!input.files?.length) {
			return;
		}
	
		const file = input.files[0];
		const reader = new FileReader();
	
		reader.onload = () => {
			const base64String = reader.result as string;
			// Remover la parte "data:image/jpeg;base64,"
			const base64WithoutPrefix = base64String.split(',')[1];
			this.dataSource.imageTest = base64WithoutPrefix;
			console.log('Image in base64 without prefix:', this.dataSource.imageTest);
		};
	
		reader.readAsDataURL(file);
	}
}