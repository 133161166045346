import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class EssayService {

    constructor(private http: HttpClient) {
    }

    findByPhaseId(idPhase: number): Observable<any> {
        const url = environment.coreUrl + `/essay/phase/${idPhase}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findVariablesById(idPhase: number, idEssay: number): Observable<any> {
        const url = environment.coreUrl + `/essay/${idPhase}/${idEssay}/vars`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findFieldsById(idEssay: number): Observable<any> {
        const url = environment.coreUrl + `/essay/${idEssay}/fields`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
