/* eslint-disable max-len */
import * as _ from 'lodash-es';
import * as moment from 'moment';

import { ActivatedRoute, Router } from '@angular/router';
import {
  Calibration,
  CalibrationAsLeft,
  CalibrationResponsibleEnum,
  CalibrationStatus,
  CalibrationVariable,
  CalibrationVariableConfig,
  ExternalCalibration,
  ExternalCalibrationVariable,
  ICalibration
} from 'src/app/model/calibration';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Variable, VariableTypeEnum } from 'src/app/model/variable';

import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { AppType, AppTypeEnum } from 'src/app/model/appType';
import { AppTypeService } from 'src/app/services/appType.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { AttachmentCalibratesService } from 'src/app/services/attachmentCalibrates.service';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { CalibrationResponsibleService } from 'src/app/services/calibrationResponsible.service';
import { CalibrationService } from 'src/app/services/calibration.service';
import { CalibrationUtils } from 'src/app/utils/calibratesUtils';
import { Client } from 'src/app/model/client';
import { ClientService } from 'src/app/services/client.service';
import { Constants } from 'src/app/utils/constants';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { DateUtils } from 'src/app/utils/dateUtils';
import { ExternalCalibrationService } from 'src/app/services/externalCalibration.service';
import { GenericClass } from 'src/app/model/genericClass';
import { InternalEquipment } from 'src/app/model/internalEquipment';
import { InternalEquipmentCustomField } from 'src/app/model/internalEquipmentCustomField';
import { InternalEquipmentCustomFieldValue } from 'src/app/model/internalEquipmentCustomFieldValue';
import { InternalEquipmentCustomFieldsService } from 'src/app/services/internalEquipmentCustomFields.service';
import { InternalEquipmentEditCreateCalibrationComponent } from './internal-equipment-edit-create-calibration.component';
import { InternalEquipmentFolder } from 'src/app/model/internalEquipmentFolder';
import { InternalEquipmentFolderService } from 'src/app/services/internalEquipmentFolderService';
import { InternalEquipmentService } from 'src/app/services/internalEquipment.service';
import { InternalEquipmentStatusEnum } from 'src/app/model/internalEquipmentStatus';
import { InternalEquipmentStatusService } from 'src/app/services/internalEquipmentStatus.service';
import { MatDialog } from '@angular/material/dialog';
import { NumberUtils } from 'src/app/utils/numberUtils';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { RoleEnum } from 'src/app/model/roleUser';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StringUtils } from 'src/app/utils/stringUtils';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { saveAs } from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UsersService } from '../../../services/users.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { MatSelectionListChange } from '@angular/material/list';
import { FieldTypeEnum } from 'src/app/model/fieldType';
import { InternalEquipmentCustomSelectValueService } from '../../../services/internalEquipmentCustomSelectValue.service';
import { InternalEquipmentCustomSelectValue } from '../../../model/internalEquipmentCustomSelectValue';

@Component({
  selector: 'app-internal-equipment-edit',
  templateUrl: './internal-equipment-edit.component.html'
})
export class InternalEquipmentEditComponent implements OnInit, OnDestroy {
  equipment: InternalEquipment;
  returnType: string;
  idClientURL: number;

  statuses: GenericClass[];
  calibrationResponsibles: GenericClass[];
  appTypes: AppType[];
  variables: Variable[];

  clients: Client[];
  clientsFiltered: Client[];
  currentUser: User;
  folders: InternalEquipmentFolder[];

  calibrations: ICalibration[];
  asLeftColsInt = ['number', 'point', 'pattern', 'average', 'correction', 'U'];
  asLeftColsExt = ['number', 'point', 'average', 'correction', 'U'];

  externalCalibration: ExternalCalibration;

  customDatepickerHeader = CustomDatepickerHeaderComponent;

  isEdit = false;
  isExternal = false;
  isInternal = false;
  pendingReview = false;

  mapCalibrationsAccordion: Map<number, boolean> = new Map();

  disableSupplier = true;
  extendedDay = true;
  stateOriginal: number;

  users: User[];
  usersFiltered: User[];

  isManager = false;

  customSelects: InternalEquipmentCustomSelectValue[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private equipmentService: InternalEquipmentService,
    private internalEquipmentCustomFieldService: InternalEquipmentCustomFieldsService,
    private internalEquipmentStatusService: InternalEquipmentStatusService,
    private clientService: ClientService,
    private appTypeService: AppTypeService,
    private variableTypeService: VariableTypeService,
    private attachmentThermalService: AttachmentThermalService,
    private attachmentCalibratesService: AttachmentCalibratesService,
    private calibrationResponsibleService: CalibrationResponsibleService,
    private calibrationService: CalibrationService,
    private externalCalibrationService: ExternalCalibrationService,
    private userService: UserService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private folderService: InternalEquipmentFolderService,
    private usersService: UsersService,
    private internalEquipmentCustomSelectValueService: InternalEquipmentCustomSelectValueService) {

    this.spinnerService.show();
    this.currentUser = this.userService.currentProfile;
    this.isExternal = this.router.url.toLowerCase().includes('external');
    this.isInternal = this.router.url.toLowerCase().includes('internal');

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;
      this.returnType = params[Constants.FIELD_RETURN] as string;
      this.idClientURL = params[Constants.FIELD_ID_CLIENT] as number;

      if (id == null) {
        this.cancel();
      }

      this.equipment = new InternalEquipment();
      if (+id === 0) {
        this.spinnerService.hide();
      } else {
        this.equipmentService.findOne(id).pipe(takeUntil(this.destroy$)).subscribe((result: InternalEquipment) => {
          if (result != null) {
            this.isEdit = true;
            this.equipment = result;
            this.equipment.calibrateDate = DateUtils.anyToDate(this.equipment.calibrateDate);
            this.equipment.expirationDate = DateUtils.anyToDate(this.equipment.expirationDate);
            this.pendingReview = this.equipment.idStatus === InternalEquipmentStatusEnum.PENDING_REVIEW;
            this.stateOriginal = this.equipment.idStatus;
            this.disableSupplier = this.isExternal || this.equipment.responsible === CalibrationResponsibleEnum.INTERNAL;

            if (this.equipment.expansionDay) {
              this.extendedDay = true;
            } else {
              this.extendedDay = false;
            }

            this.internalEquipmentCustomFieldService.findAll().subscribe(res => {

              const fields = res.content as InternalEquipmentCustomField[];

              this.equipment.customFields = fields.map(f => {
                const originalField = this.equipment.customFields?.find(f1 => f1.idCustomField === f.id);
                const newValue = new InternalEquipmentCustomFieldValue();

                newValue.idCustomField = f.id;
                newValue.idType = f.idType;
                newValue.name = f.name;
                if (originalField != null) {
                  newValue.id = originalField.id;
                  newValue.value = originalField.value;
                }

                if (f.idType === FieldTypeEnum.SELECT_ONE) {
                  this.internalEquipmentCustomSelectValueService.findByIdField(f.id).subscribe(customSelect => {
                    newValue.valueSelect = customSelect;
                    this.customSelects = customSelect;
                  })
                }

                return newValue;
              });
            });
            this.loadCalibrations(id);
          } else {
            this.equipment = null;
          }
        }, () => {
          this.equipment = null;
        }, () => {
          if (this.equipment == null) {
            this.cancel();
          }
          this.spinnerService.hide();
        });

      }
    });
  }

  ngOnInit(): void {
    this.statuses = [];
    this.clients = [];
    this.appTypes = [];
    this.variables = [];
    this.calibrationResponsibles = [];

    this.folders = [];
    this.folderService.getAll().pipe(takeUntil(this.destroy$)).subscribe((data: InternalEquipmentFolder[]) => this.folders = data);

    if (this.isInternal) {
      this.internalEquipmentStatusService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => {
        if (!this.pendingReview) {
          data = data.filter(d => d.id !== InternalEquipmentStatusEnum.PENDING_REVIEW);
        }

        this.statuses = data;
      });

      this.appTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: AppType[]) => this.appTypes = data);
      this.calibrationResponsibleService.findAll().pipe(takeUntil(this.destroy$))
        .subscribe((data: GenericClass[]) => this.calibrationResponsibles = data);
    }

    if (this.isExternal) {
      this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => {
        this.clients = data;
        this.clientsFiltered = this.clients;

        if (this.equipment.id == null && this.idClientURL != null) {
          this.equipment.idClient = +this.idClientURL;
        }
      });
    }

    const disallowedVariables: number[] = [VariableTypeEnum.LETHALITY];
    this.variableTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Variable[]) => {
      this.variables = data.filter(v => !disallowedVariables.includes(v.id));
    });


  this.users = [];
  this.isManager = this.userService.currentProfile?.manager || this.userService.currentProfile?.admin;
  this.usersService.findAllFromGroupAppType(AppTypeEnum.CALIBRATES).pipe(takeUntil(this.destroy$))
    .subscribe((data: User[]) => {
      this.users = data;
      this.usersFiltered = this.users;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChangeResponsibleCheck(event): void {
    const idResponsible = event.source.value as number;
    this.disableSupplier = this.isExternal || idResponsible === CalibrationResponsibleEnum.INTERNAL;

    if (this.disableSupplier) {
      this.equipment.supplier = null;
    }
  }

  saveDate(field: string, event: any): void {

    if (event.target.value != null) {
      const date = moment(`${event.target.value as string} 12:00:00 + 0:00`, 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      this.equipment[field] = date;
      if (this.equipment?.expansionDay) {
        this.onChangeDateExpanded();
      }
    } else {
      this.equipment[field] = null;
    }

  }

  getAsLeftList(variable: CalibrationVariable): CalibrationAsLeft[] {
    const res: CalibrationAsLeft[] = [];

    variable.config.forEach((config, indexConfig) => {
      config.asLeft.forEach(aL => {
        aL.indexConfig = indexConfig;

        aL.uncertaintyResolution = config.uncertaintyResolution;

        res.push(aL);
      });
    });

    return res.sort((c1, c2) => c1.id - c2.id);
  }

  isCalibrationOpened(index: number): boolean {
    return this.mapCalibrationsAccordion.get(index);
  }

  setCalibrationOpened(index: number, state: boolean): void {
    this.mapCalibrationsAccordion.set(index, state);
  }

  newExternalCalibration(): void {
    const item = new ExternalCalibration();

    item.calibrationDate = new Date();
    item.calibrationDate = moment().toDate();
    if (this.equipment.calibrationFrequency && this.equipment.calibrationFrequency?.match('^[0-9]+$')) {
      item.expirationDate = _.cloneDeep(item.calibrationDate);
      item.expirationDate.setMonth(item.expirationDate.getMonth() + Number(this.equipment.calibrationFrequency));
    } else {
      item.expirationDate = _.cloneDeep(item.calibrationDate);
      item.expirationDate.setFullYear(item.expirationDate.getFullYear() + 1);
    }
    item.idEquipment = this.equipment.id;
    item.variables = [];
    this.equipment.idsVariables.forEach(idVar => {
      const variable = new ExternalCalibrationVariable();
      variable.idVariable = idVar;

      const vari = this.variables.find(v => v.id === idVar);
      variable.variableTranslation = vari.translation;

      item.variables.push(variable);
    });

    const dialogRef = this.dialog.open(InternalEquipmentEditCreateCalibrationComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        item,
        idEquipment: this.equipment.id,
        nameEquipment: this.equipment.name
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExternalCalibration) => {

      if (result != null) {
        this.externalCalibration = result;
        const iCalibration: ICalibration = {
          calibrationDate: result.calibrationDate,
          certificateNum: result.certificateNum,
          expirationDate: result.expirationDate,
          idEquipment: result.idEquipment,
          internal: result.internal,
          id: result.id
        };
        this.calibrations.push(iCalibration);
        this.onChangeDateExpanded();
      }
    });
  }

  goToCalibration(idCalibration: number): void {
    let url: string;
    if (this.router.url.includes('calibrates')){
      url = `calibrates/calibration?id=${idCalibration}`
    } else if (this.router.url.includes('thermal')) {
      url = `thermal/calibration?id=${idCalibration}`
    }
    void this.router.navigateByUrl(url);
  }

  editExternalCalibration(idExternalCalibration: number): void {
    event.stopPropagation();

    this.spinnerService.show();

    this.externalCalibrationService.findOne(idExternalCalibration).pipe(takeUntil(this.destroy$)).subscribe(row => {

      row = CalibrationUtils.objectToExternalCalibration(row);

      this.spinnerService.hide();
      const dialogRef = this.dialog.open(InternalEquipmentEditCreateCalibrationComponent, {
        minWidth: '50%',
        maxHeight: '95vh',
        data: {
          item: _.cloneDeep(row) as ExternalCalibration,
          idEquipment: this.equipment.id,
          nameEquipment: this.equipment.name
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExternalCalibration) => {
        if (result != null) {
          this.externalCalibration = result;
          result.expirationDate = _.cloneDeep(result.calibrationDate);
          result.expirationDate.setFullYear(result.expirationDate.getFullYear() + 1);
          const iCalibration: ICalibration = {
            calibrationDate: result.calibrationDate,
            certificateNum: result.certificateNum,
            expirationDate: result.expirationDate,
            idEquipment: result.idEquipment,
            internal: result.internal,
            id: result.id
          };
          const calIndex = this.calibrations.findIndex(e => e.id === result.id);
          this.calibrations[calIndex] = iCalibration;
          this.onChangeDateExpanded();
        }
      });
    });
  }

  deleteExternalCalibration(idExternalCalibration: number): void {
    event.stopPropagation();

    this.spinnerService.show();

    this.externalCalibrationService.delete(idExternalCalibration).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentList.dialog.delete.ok') as string);
      this.spinnerService.hide();
      this.reloadEquipment();
    }, () => {
      this.snackBarService.sendError(this.translate.instant('calibrateEquipmentList.dialog.delete.error') as string);
      this.spinnerService.hide();
    });
  }

  downloadCertificateEquipment(index: number): void {
    event.stopPropagation();

    const calibration = this.calibrations[index];

    if (calibration && calibration.id) {
      this.spinnerService.show();

      let call: Observable<any> = null;

      if (calibration.internal) {
        call = this.attachmentCalibratesService.downloadCertificateCalibration(calibration.id);
      } else {
        call = this.attachmentCalibratesService.downloadCertificateExternalCalibration(calibration.id);
      }

      if (call == null) {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.downloadPdf.error') as string);

        return;
      }

      call.pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        saveAs(res, this.equipment.name + '.pdf');
        this.spinnerService.hide();
      }, error => {
        console.error(error);
        this.spinnerService.hide();
      });
    }

  }

  downloadCertificateLastCertificateCalibration(index: number): void {
    event.stopPropagation();

    const calibration = this.calibrations[index];

    if (calibration && calibration.id) {
      this.spinnerService.show();

      this.calibrationService.downloadPdfLastVersion(calibration.id).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        saveAs(res, calibration.certificateNum + '.pdf');
        this.spinnerService.hide();
      }, error => {
        console.error(error);
        this.spinnerService.hide();
      });
    }

  }

  canSave(): boolean {
    const rolesCanSaveInternal = [RoleEnum.ADMIN, RoleEnum.MANAGER, RoleEnum.RESPONSABLE];

    return this.isExternal || rolesCanSaveInternal.includes(this.currentUser.idActiveRole) && !this.pendingReview;
  }

  reviewEquipment(): void {
    if (this.isResponsibleEquipment()) {
      this.requestReason((reason) => {
        this.requestSign((token: string) => {
          this.equipmentService.findOne(this.equipment.id).pipe(takeUntil(this.destroy$)).subscribe((result: InternalEquipment) => {
            result.idStatus = InternalEquipmentStatusEnum.EN_USO;

            this.equipmentService.save(result, reason).pipe(takeUntil(this.destroy$)).subscribe((res: InternalEquipment) => {
              this.equipment = res;

              this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.update.ok') as string);

              this.reloadEquipment();
            }, err => {
              if (err != null && err.error != null && typeof err.error === 'string') {
                this.snackBarService.sendError(err.error as string);
              } else {
                this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.update.error') as string);
              }
            });
          });
        });
      });
    } else {
      if (!this.equipment.responsibleEquipment) {
        this.requestResponsibleEquipment((confirm) => {
          if (confirm) {
            this.requestReason((reason) => {
              this.requestSign((token: string) => {
                this.equipmentService.findOne(this.equipment.id).pipe(takeUntil(this.destroy$)).subscribe((result: InternalEquipment) => {
                  result.idStatus = InternalEquipmentStatusEnum.EN_USO;
                  result.responsibleEquipment = this.currentUser.username;
                  this.equipmentService.save(result, reason).pipe(takeUntil(this.destroy$)).subscribe((res: InternalEquipment) => {
                    this.equipment = res;

                    this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.update.ok') as string);

                    this.reloadEquipment();
                  }, err => {
                    if (err != null && err.error != null && typeof err.error === 'string') {
                      this.snackBarService.sendError(err.error as string);
                    } else {
                      this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.update.error') as string);
                    }
                  });
                });
              });
            });
          } else {
            this.requestSaveIfNotResponsibleEquipment((acceptSave) => {
              if (acceptSave) {
                this.requestReason((reason) => {
                  this.requestSign((token: string) => {
                    this.equipmentService.findOne(this.equipment.id).pipe(takeUntil(this.destroy$))
                    .subscribe((result: InternalEquipment) => {
                      result.idStatus = InternalEquipmentStatusEnum.EN_USO;

                      this.equipmentService.save(result, reason).pipe(takeUntil(this.destroy$)).subscribe((res: InternalEquipment) => {
                        this.equipment = res;

                        this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.update.ok') as string);

                        this.reloadEquipment();
                      }, err => {
                        if (err != null && err.error != null && typeof err.error === 'string') {
                          this.snackBarService.sendError(err.error as string);
                        } else {
                          this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.update.error') as string);
                        }
                      });
                    });
                  });
                });
              } else {
                this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.resposible.equipment.error') as string);
              }
            });
          }
        });
      } else {
        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.resposible.equipment.error') as string);
      }
    }
  }

  saveEquipment(): void {
    this.equipment.internal = this.isInternal;
    this.equipment.expirationDate = moment(this.equipment.expirationDate, 'DD/MM/YYYY').toDate();
    this.equipment.calibrateDate = moment(this.equipment.calibrateDate, 'DD/MM/YYYY').toDate();

    const errores: string[] = [];

    if (StringUtils.isEmpty(this.equipment.name)) {
      errores.push(this.translate.instant('internalEquipmentEdit.general.name.error') as string);
    }

    if (StringUtils.isEmpty(this.equipment.equipment)) {
      errores.push(this.translate.instant('internalEquipmentEdit.general.equipment.error') as string);
    }

    if (StringUtils.isEmpty(this.equipment.maker)) {
      errores.push(this.translate.instant('internalEquipmentEdit.general.maker.error') as string);
    }

    if (StringUtils.isEmpty(this.equipment.serialNum)) {
      errores.push(this.translate.instant('internalEquipmentEdit.general.serialNum.error') as string);
    }

    if (this.equipment.internal && this.equipment.idStatus == null) {
      errores.push(this.translate.instant('internalEquipmentEdit.general.status.error') as string);
    }

    if (!this.equipment.internal && this.equipment.idClient == null) {
      errores.push(this.translate.instant('internalEquipmentEdit.general.client.error') as string);
    }

    if (this.equipment.internal && this.equipment.responsible == null) {
      errores.push(this.translate.instant('internalEquipmentEdit.general.calibrationResponsible.error') as string);
    }

    if (!StringUtils.isEmpty(this.equipment.calibrationFrequency)) {
      const vNumber = new RegExp('^[0-9]+$');
      if (!vNumber.test(this.equipment.calibrationFrequency)) {
        errores.push(this.translate.instant('internalEquipmentEdit.general.calibrationFrequencyType.error') as string);
      }
    }

    if (errores.length === 0) {
      this.save(this.equipment.id == null);
    } else if (errores.length !== 0) {
      this.snackBarService.sendError(errores.join('\n'));
    }

  }

  showUploadCertificateToInternalEquipment(): boolean {
    const allowed = [CalibrationResponsibleEnum.EXTERNAL];

    return allowed.includes(this.equipment.responsible);
  }

  uploadCertificateToInternalEquipment(event): void {
    const file = event.target.files[0] as File;

    this.spinnerService.show();
    this.attachmentThermalService.uploadCertificateToInternalEquipment(this.equipment.id, file).pipe(takeUntil(this.destroy$))
      .subscribe((item: number) => {
        this.equipment.idCertificate = item;

        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.ok') as string);
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.error') as string);
      });
  }

  uploadCertificateToCalibration(index: number, eventFile): void {
    event.stopPropagation();

    const file = eventFile.target.files[0] as File;

    const calibration = this.calibrations[index];

    if (calibration && calibration.id) {
      this.spinnerService.show();

      let call: Observable<any> = null;

      if (calibration.internal) {
        call = this.attachmentThermalService.uploadCertificateToCalibration(calibration.id, file);
      } else {
        call = this.externalCalibrationService.uploadCertificateToCalibration(calibration.id, file);
      }

      if (call == null) {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.error') as string);

        return;
      }

      call.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.ok') as string);

        this.loadCalibrations();
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.error') as string);
      });
    }

  }

  deleteCertificateToCalibration(index: number): void {
    event.stopPropagation();

    const calibration = this.calibrations[index];

    if (calibration && calibration.id) {
      this.spinnerService.show();

      let call: Observable<any> = null;

      if (calibration.internal) {
        call = this.attachmentThermalService.deleteCertificateToCalibration(calibration.id);
      } else {
        call = this.externalCalibrationService.deleteCertificateToCalibration(calibration.id);
      }

      if (call == null) {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.error') as string);

        return;
      }

      call.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.ok') as string);
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.uploadCertificate.error') as string);
      });
    }

  }

  downloadPdf(): void {
    this.spinnerService.show();

    const id = this.equipment.id;
    this.equipmentService.downloadPdf(id).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const n = this.translate.instant('internalEquipmentEdit.files.certificateInternalEquipment', { name: this.equipment.name }) as string;
      saveAs(res, n);

      this.spinnerService.hide();
    }, () => {
      this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.downloadPdf.error') as string);

      this.spinnerService.hide();
    });
  }

  downloadAuditPdf(): void {
    this.spinnerService.show();

    const id = this.equipment.id;
    this.equipmentService.downloadAuditPdf(id).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = this.translate.instant('internalEquipmentEdit.files.auditInternalEquipment', { name: this.equipment.name }) as string;
      saveAs(res, name);

      this.spinnerService.hide();
    }, () => {
      this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.downloadPdf.error') as string);

      this.spinnerService.hide();
    });
  }

  getDecimalsResolution(resolution: number): number {
    let res = NumberUtils.countDecimals(resolution);

    if (resolution === 1) {
      res = 0;
    }

    return res;
  }

  getDecimalsUncertainty(uncertaintyResolution: number): number {
    let res = 0;
    const uncertaintyDecimals = NumberUtils.countDecimals(uncertaintyResolution);

    if (uncertaintyDecimals === 0) {
      res = uncertaintyResolution;
    } else {
      res = uncertaintyDecimals;
    }

    return res;
  }

  getDecimalsPattern(pattern: number): number {
    return NumberUtils.countDecimals(pattern);
  }

  cancel(): void {
    const type = this.getTypeEquipment();
    let url: string;
    if (this.router.url.includes('calibrates')){
      url = `calibrates/${type}Equipments`;
    } else if (this.router.url.includes('thermal')) {
      url = `thermal/${type}Equipments`;
    } else if (this.router.url.includes('air')) {
      url = `air/${type}Equipments`;
    }
    void this.router.navigateByUrl(url);
  }

  isNotValid(calibration: Calibration): boolean {
    return calibration.idStatus === CalibrationStatus.NO_VALIDO;
  }

  hasCalibrationPlan(): boolean {
    return this.currentUser.showCalibrationPlan;
  }

  disablesSupplier(): boolean {
    return this.disableSupplier || !this.hasCalibrationPlan();
  }

  getFolderName(item: InternalEquipmentFolder): string {
    if (item == null) {
      return '';
    }

    let prefix = '';
    if (item.idFather != null) {
      const parent = this.folders.find(f => f.id === item.idFather);
      prefix = this.getFolderName(parent).concat(' > ');
    }

    return prefix.concat(item.name);
  }

  showCalibrationPlan(): boolean {
    return this.currentUser.showCalibrationPlan;
  }

  decline(): void {
    if (this.isResponsibleEquipment()) {
      this.requestReason((reason) => {
        this.spinnerService.show();
        this.equipment.idStatus = InternalEquipmentStatusEnum.FUERA_DE_USO;
          this.equipmentService.save(this.equipment, reason).pipe(takeUntil(this.destroy$)).subscribe((res: InternalEquipment) => {
            this.equipment = res;

            this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.update.ok') as string);

            this.reloadEquipment();

          }, err => {
            if (err != null && err.error != null && typeof err.error === 'string') {
              this.snackBarService.sendError(err.error as string);
            } else {
              this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.update.error') as string);
            }
          });
      });
    } else {
      if (!this.equipment.responsibleEquipment) {
        this.requestReason((reason) => {
          this.spinnerService.show();
          this.equipment.idStatus = InternalEquipmentStatusEnum.FUERA_DE_USO;
            this.equipmentService.save(this.equipment, reason).pipe(takeUntil(this.destroy$)).subscribe((res: InternalEquipment) => {
              this.equipment = res;

              this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.update.ok') as string);

              this.reloadEquipment();

            }, err => {
              if (err != null && err.error != null && typeof err.error === 'string') {
                this.snackBarService.sendError(err.error as string);
              } else {
                this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.update.error') as string);
              }
            });
        });
      } else {
        this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.resposible.equipment.error') as string);
      }
    }
  }

  onChangeDateExpanded(): void {
    if (this.extendedDay && this.equipment?.expansionDay) {
      this.equipment.idStatus = InternalEquipmentStatusEnum.EXTENDIDO;
      const dateExpired = _.cloneDeep(this.equipment.expirationDate);
      // eslint-disable-next-line max-len
      const result = dateExpired.setDate(this.equipment.expirationDate.getDate() + this.equipment.expansionDay);
      this.equipment.extendedExpirationDate = DateUtils.anyToDate(result);
    }
  }

  onChangeDateExpandedCheck(event: MatCheckboxChange): void {
    this.extendedDay = event.checked;
    if (!event.checked) {
      this.equipment.extendedExpirationDate = null;
      this.equipment.expansionDay = null;
      this.equipment.idStatus = this.stateOriginal;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.equipment.idStatus = InternalEquipmentStatusEnum.EXTENDIDO;
    }
  }

  checkExtedend(): boolean {
    return this.extendedDay;
  }

  onChangeNotificationResponsible(event: MatCheckboxChange): void {
    this.equipment.notificationResposibleEq = event.checked;
  }

  setValue(field: InternalEquipmentCustomFieldValue, number: number, value: string | MatCheckboxChange | MatSelectionListChange):void {
    if (value instanceof MatCheckboxChange) {
      value = value.checked.toString();
    } else if (value instanceof MatSelectionListChange) {
      value = value.source.selectedOptions.selected.map(i => i.value as string).join(';');
    }

    field.value = value
  }
  private save(isNew: boolean) {
    this.requestReason((reason) => {
      this.requestSign(() => {
        this.spinnerService.show();
          this.equipmentService.save(this.equipment, reason).pipe(takeUntil(this.destroy$)).subscribe((res: InternalEquipment) => {
            this.equipment = res;
            if (this.externalCalibration) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              this.externalCalibrationService.save(this.externalCalibration, this.externalCalibration.uploadFile)
              .pipe(takeUntil(this.destroy$)).subscribe(() => {
                if (isNew) {
                  this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.create.ok') as string);
                } else {
                  this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.update.ok') as string);
                }

                if (StringUtils.isNotEmpty(this.returnType)) {
                  if (this.returnType === 'createCalibration') {
                    let url: string;
                    if (this.router.url.includes('calibrates')){
                      url = `calibrates/calibration?id=0&idEquipment=${this.equipment.id}`
                    } else if (this.router.url.includes('thermal')) {
                      url = `thermal/calibration?id=0&idEquipment=${this.equipment.id}`
                    }
                    void this.router.navigateByUrl(url);

                    return;
                  }
                }
                this.spinnerService.hide();
                this.reloadEquipment();
              }, () => {
                const isCreation = this.externalCalibration.id != null;

                if (isCreation) {
                  this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.create.error') as string);
                } else {
                  this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.update.error') as string);
                }
                this.spinnerService.hide();
              });
            } else {
              if (isNew) {
                this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.create.ok') as string);
              } else {
                this.snackBarService.sendSuccess(this.translate.instant('internalEquipmentEdit.form.update.ok') as string);
              }

              if (StringUtils.isNotEmpty(this.returnType)) {
                if (this.returnType === 'createCalibration') {
                  let url: string;
                  if (this.router.url.includes('calibrates')){
                    url = `calibrates/calibration?id=0&idEquipment=${this.equipment.id}`
                  } else if (this.router.url.includes('thermal')) {
                    url = `thermal/calibration?id=0&idEquipment=${this.equipment.id}`
                  }
                  void this.router.navigateByUrl(url);
                  this.spinnerService.hide();
                  return;
                }
              }
              this.reloadEquipment();
            }
          }, err => {
            if (err != null && err.error != null && typeof err.error === 'string') {
              this.snackBarService.sendError(err.error as string);
              this.spinnerService.hide();

            } else if (isNew) {
              this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.create.error') as string);
              this.spinnerService.hide();

            } else {
              this.snackBarService.sendError(this.translate.instant('internalEquipmentEdit.form.update.error') as string);
              this.spinnerService.hide();

            }
          }, () => {
            this.spinnerService.hide();
          });
      });
    });

  }

  private reloadEquipment() {
    const type = this.getTypeEquipment();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    let url: string;
    if (this.router.url.includes('calibrates')){
      url = `calibrates/${type}Equipment?id=${this.equipment.id}`;
    } else if (this.router.url.includes('thermal')) {
      url = `thermal/${type}Equipment?id=${this.equipment.id}`;
    } else if (this.router.url.includes('air')) {
      url = `air/${type}Equipment?id=${this.equipment.id}`;
    }
    void this.router.navigateByUrl(url);
  }

  private loadCalibrations(id: number = this.equipment.id) {
    this.calibrations = [];

    if (id == null || id === 0) {
      return;
    }

    this.calibrationService.findAllFromEquipment(id).pipe(takeUntil(this.destroy$)).subscribe((calibs: Calibration[]) => {

      calibs.forEach(calib => {
        calib.internal = true;
        calib.variables.forEach((variable, vIndex) => {
          variable.config.forEach(config => {
            config.asLeft = config.asLeft.map(aL => {
              aL = CalibrationUtils.objectToAsLeft(aL);

              const foundConfig = this.findConfig(calib, vIndex, aL.point);

              aL.resolution = foundConfig.resolution;

              const patterns = variable.patterns.map(p => {
                if (ArrayUtils.isEmpty(p.values)) {
                  return null;
                }

                const res = p.values.filter(point => point.value === aL.point);

                if (ArrayUtils.isEmpty(res)) {
                  return null;
                } else {
                  return res[0];
                }

              }).filter(p => p != null).map(p => p.uncertainty);

              aL.patterns = patterns;

              return aL;
            });
          });
        });
        this.calibrations.push(calib);
      });

      this.calibrations.sort((a, b) => DateUtils.anyToDate(a.calibrationDate).getTime() - DateUtils.anyToDate(b.calibrationDate).getTime())
        .reverse();

      for (let i = 0; i < this.calibrations.length; i++) {
        this.mapCalibrationsAccordion.set(i, i === 0);
      }

    });

    this.externalCalibrationService.findAllFromEquipment(id).pipe(takeUntil(this.destroy$)).subscribe((calibs: ExternalCalibration[]) => {
      calibs.forEach(calib => {

        calib = CalibrationUtils.objectToExternalCalibration(calib);

        calib.internal = false;
        this.calibrations.push(calib);
      });

      this.calibrations.sort((a, b) => a.calibrationDate.getTime() - b.calibrationDate.getTime()).reverse();

      for (let i = 0; i < this.calibrations.length; i++) {
        this.mapCalibrationsAccordion.set(i, i === 0);
      }
    });

  }

  private getTypeEquipment() {
    let res = '';

    if (this.isExternal) {
      res = 'external';
    } else if (this.isInternal) {
      res = 'internal';
    }

    return res;
  }

  private findConfig(calibration: Calibration, indexVariable: number, point: number): CalibrationVariableConfig {
    const configs = calibration.variables[indexVariable].config;

    let config: CalibrationVariableConfig = null;

    if (!ArrayUtils.isEmpty(configs)) {
      if (point != null) {
        config = configs.find(c => NumberUtils.isBetweenEq(point, c.rangeInit, c.rangeEnd));
      }

      if (config == null && point != null) {
        config = configs.find(c => c.asLeft.map(aL => aL.point).includes(point));
      }

      if (config == null) {
        config = configs[0];
      }
    }

    return config;
  }

  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private requestSign(callback: (token: string) => void) {
    this.spinnerService.hide();

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private isResponsibleEquipment(): boolean {
    if (this.equipment?.responsibleEquipment) {
      if (this.equipment.responsibleEquipment === this.userService.currentProfile.username) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  private requestResponsibleEquipment(callback: (result: boolean) => void) {
    const message = this.translate.instant('internalEquipmentEdit.dialog.confirmeResponsible') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((response: boolean) => {
      callback(response);
    });
  }

  private requestSaveIfNotResponsibleEquipment(callback: (result: boolean) => void) {
    const message = this.translate.instant('internalEquipmentEdit.dialog.NoconfirmeResponsible') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((response: boolean) => {
      callback(response);
    });
  }

}
