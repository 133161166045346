import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { DevextremeModule } from 'src/app/external-components/devextreme.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ProtocolQEditComponent } from './protocol-edit.component';
import { QuillModule } from 'ngx-quill';
import Quill from 'quill';
import { SharedModule } from '../../shared/shared.module';
import { ProtocolEditConfirmSaveComponent } from './protocol-edit-confirm-save/protocol-edit-confirm-save.component';
import { ProtocolQEditAuditComponent } from './protocol-q-edit-audit/protocol-q-edit-audit.component';
import { ProtocolQEditTestComponent } from './protocol-q-edit-test/protocol-q-edit-test.component';
import { ProtocolEditEquipmentDialogComponent } from './protocol-edit-equipment-dialog/protocol-edit-equipment-dialog.component';
import { ProtocolQEditSectionTemplateComponent } from './protocol-q-edit-section-template/protocol-q-edit-section-template.component';
import { ProtocolQEditGenerateReportComponent } from './protocol-q-edit-generate-report/protocol-q-edit-generate-report.component';

@NgModule({
  declarations: [
    ProtocolQEditComponent,
    ProtocolEditConfirmSaveComponent,
    ProtocolQEditAuditComponent,
    ProtocolQEditTestComponent,
    ProtocolEditEquipmentDialogComponent,
    ProtocolQEditSectionTemplateComponent,
    ProtocolQEditGenerateReportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),

    /* Custom modules */
    AngularMaterialModule,
    DevextremeModule,
    SharedModule,
    PipesModule,
    

    /* Otros */
    FlexLayoutModule
  ]
})
export class ProtocolQEditModule { }
