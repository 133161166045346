import { Area } from './area';
import { CriticalInstrumentationExecution } from './criticalInstrumentation';
import { EquipmentExecution } from './equipment';
import { Essay } from './essay';
import { GenericAttachment, GenericFilter } from './genericClass';
import { Protocol, ProtocolAir } from './protocol';
import { ReferenceDocument } from './referenceDocument';
import { ValidatorSpecifics } from './validatorSpecific';

export class Execution {
    id: number;

    idProtocol: number;
    nameProtocol: string;
    originalProtocolVersion: number;
    protocol: Protocol; // Puede no venir, no usar si no se está seguro

    idProcess: number;
    processTranslation: string;

    idClient: number;
    nameClient: string;
    clientAddress: string;

    idPhase: number;
    phaseTranslation: string;

    usernameProjectManager: string;

    idInstrumentation: number; // FIXME: A desaparecer
    nameInstrumentation: string; // FIXME: A desaparecer

    regUser: string;
    modUser: string;

    regFc: Date;
    modFc: Date;

    idStatus: number;
    statusTranslation: string;

    reason: string;

    deviations: string;
    observations: string;

    according: boolean;
    accordingReason: string;

    projectNo: string;
    documentCode: string;
    clientDocumentCode: string;
    currentVersion: string;

    department: string;

    equipments: EquipmentExecution[] = [];
    essays: Essay[] = [];
    criticalInstrumentations: CriticalInstrumentationExecution[] = [];
    referenceDocs: ReferenceDocument[];

    // instrumentations: Instrumentation[]; // TODO: A implementar
    validator: ValidatorSpecifics; // FIXME: A desaparecer

    initWorkDate: Date;
    endWorkDate: Date;

    realizationDate: Date;
    conclusions: string;
}

export class ExecutionAudit {
    id: number;
    username: string;

    idAction: number;
    actionName: string;
    actionTranslation: string;

    idStatus: number;
    nameStatus: string;
    statusTranslation: string;

    date: Date;

    currentVersion: string;

    changes: string;

    canDownload: boolean;
}

export class ExecutionAttachment extends GenericAttachment {
    idExecution: number;
}

export class ExecutionFilter extends GenericFilter {
    projectNo: string;
    documentCode: string;
    clientDocumentCode: string;
    documentCodeProtocol: string;

    idProtocol: number;

    idProcess: string;
    idClient: number;
    idPhase: number;
    idInstrumentation: number;

    idStatus: number;

    regUser: string;
    modUser: string;
    projectManager: string;

    regFcStart: Date;
    regFcEnd: Date;

    modFcStart: Date;
    modFcEnd: Date;

    workStartFcStart: Date;
    workStartFcEnd: Date;

    workEndFcStart: Date;
    workEndFcEnd: Date;

    realizationFcStart: Date;
    realizationFcEnd: Date;

    equipment: string;
    equipmentMaker: string;
    equipmentModel: string;
    equipmentSerialNum: string;
    equipmentInternalId: string;
    equipmentLocation: string;

    department: string;

    areaName: string;

    cols: string[];
}

export class ExecutionAir {
    id: number;

    idProtocol: number;
    nameProtocol: string;
    originalProtocolVersion: number;
    protocol: ProtocolAir; // Puede no venir, no usar si no se está seguro

    idProcess: number;
    processTranslation: string;

    idClient: number;
    nameClient: string;
    clientAddress: string;

    usernameProjectManager: string;

    regUser: string;
    modUser: string;

    regFc: Date;
    modFc: Date;

    idStatus: number;
    statusTranslation: string;

    reason: string;

    deviations: string;
    observations: string;
    notes: string;

    customDeviations: string;
    customObservations: string;

    according: boolean;
    accordingReason: string;

    projectNo: string;
    documentCode: string;
    clientDocumentCode: string;
    currentVersion: string;

    referenceDocs: ReferenceDocument[];

    initWorkDate: Date;
    endWorkDate: Date;

    realizationDate: Date;
    conclusions: string;

    areas: Area[];
    areaNames: string;
    workOffline: boolean;
}

export class ExecutionAuditFilter extends GenericFilter {
}

export class ExecutionAttachmentFilter extends GenericFilter {
}

export enum ExecutionStatus {
    EN_EJECUCION = 2,
    PENDIENTE_FIRMA = 4,
    FIRMADO = 5
}

export enum ExecutionActionEnum {
    CREATE = 1,
    SAVE = 2,
    PREPARE = 3,
    EXECUTE = 4,
    VALIDATED_BY_TECHNICIAN = 5,
    INVALIDATE_BY_TECHNICIAN = 6,
    VALIDATED_BY_DOCUMENTATION = 7,
    INVALIDATE_BY_DOCUMENTATION = 8,
    UPLOAD_PHOTO_SENSOR = 9,
    UPLOAD_EXCEL_SENSOR = 10,
    UPLOAD_PRIMARY_DATA_SENSOR = 11,
    UPLOAD_ATTACHMENT = 12,
    DELETE_ATTACHMENT = 13,
    MODIFY_CRITICAL_INSTR = 14,
    MODIFY_ESSAY = 15,
    MODIFY_SENSOR = 16,
    MODIFY_FIELD = 17,
    MODIFY_CRITERIA = 18,
    MODIFY_EQUIPMENT = 19
}
