import { Client } from '../model/client';
import { Group } from '../model/group';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SensorData } from '../model/sensorData';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AttachmentThermalService {

    constructor(private http: HttpClient) {
    }

    uploadGeneric(file: File, idType: number = 99): Observable<any> {
        const url = environment.thermalUrl + '/attachment/';
        const form = new FormData();
        form.append('file', file, file.name);
        form.append('idType', idType.toString());

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadGeneralPhoto(idExecution: number, idEssay: number, file: File): Observable<any> {
        const url = environment.thermalUrl + `/attachment/execution/${idExecution}/essay/${idEssay}/photo`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadPhotoToSensor(idSensor: number, file: File): Observable<any> {
        const url = environment.thermalUrl + `/attachment/sensor/${idSensor}/photo/`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    rotateSensorImageLeft(idSensor: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/sensor/${idSensor}/rotate/left`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    rotateSensorImageRight(idSensor: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/sensor/${idSensor}/rotate/right`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadPrimaryFileToSensor(idSensor: number, file: File): Observable<any> {
        const url = environment.thermalUrl + `/attachment/sensor/${idSensor}/primary/`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadExcelToSensor(idSensor: number, file: File, data: SensorData[]): Observable<any> {

        data = data.filter(item => item.date != null && !isNaN(item.date.getTime()) && item.value != null);

        const dataCopy = data.map(a => ({ ...a }));
        dataCopy.forEach(d => {
            delete d.idVariable;
            delete d.equipmentName;
            delete d.serialNum;
            delete d.idEquipment;
        });

        const url = environment.thermalUrl + `/attachment/sensor/${idSensor}/excel/`;
        const form = new FormData();
        form.append('file', file, file.name);
        form.append('data', JSON.stringify(dataCopy));

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    convertExcelToCsv(file: File): Observable<any> {
        const url = environment.thermalUrl + '/attachment/sensor/excel/convertExcelToCsv';
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form, { responseType: 'blob' });
    }

    convertPdfToCsv(file: File, idVariable: number): Observable<any> {
        const url = environment.thermalUrl + '/attachment/sensor/pdf/convertPdfToCsv';
        const form = new FormData();
        form.append('file', file, file.name);
        form.append('idVariable', idVariable.toString());

        return this.http.post(url, form, { responseType: 'blob' });
    }

    uploadCertificateToInternalEquipment(idEquipment: number, file: File): Observable<any> {
        const url = environment.thermalUrl + `/attachment/internalEquipment/${idEquipment}/certificate/`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadCertificateToInternalEquipmentMassive(idEquipment: number, file: File): Observable<any> {
        const url = environment.calibratesUrl + `/internalEquipment/${idEquipment}/certificate/massive`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadCertificateToCalibration(idCalibration: number, file: File): Observable<any> {
        const url = environment.calibratesUrl + `/attachment/calibration/${idCalibration}/certificate/`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    deleteCertificateToCalibration(idCalibration: number): Observable<any> {
        const url = environment.calibratesUrl + `/attachment/calibration/${idCalibration}/certificate/`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadCertificateToCriticalInstrumentation(id: number, file: File): Observable<any> {
        const url = environment.thermalUrl + `/attachment/criticalInstrumentation/${id}/certificate/`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadCertificateToUser(username: string, file: File): Observable<any> {
        const url = environment.coreUrl + `/attachment/user/${username}/certificate/`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadAttachmentToProtocol(idProtocol: number, file: File): Observable<any> {
        const url = environment.thermalUrl + `/attachment/protocol/${idProtocol}/attachment/`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadAttachmentToProtocol(idProtocol: number, idAttachment: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/protocol/${idProtocol}/attachment/${idAttachment}`;

        return this.http.get(url, { responseType: 'blob' });
    }

    deleteAttachmentToProtocol(idProtocol: number, idAttachment: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/protocol/${idProtocol}/attachment/${idAttachment}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadAttachmentToExecution(idExecution: number, file: File, type: number = 99): Observable<any> {
        const url = environment.thermalUrl + `/attachment/execution/${idExecution}/attachment/`;
        const form = new FormData();
        form.append('file', file, file.name);

        if (type != null) {
            form.append('type', type.toString());
        }

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadAttachmentToExecution(idExecution: number, idAttachment: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/execution/${idExecution}/attachment/${idAttachment}`;

        return this.http.get(url, { responseType: 'blob' });
    }

    deleteAttachmentToExecution(idExecution: number, idAttachment: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/execution/${idExecution}/attachment/${idAttachment}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadCertificateUser(username: string): Observable<any> {
        const url = environment.coreUrl + `/attachment/user/${username}/certificate/`;

        return this.http.get(url, { responseType: 'blob' });
    }

    downloadCertificateBioindicatorEquipment(idCertificate: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/bioindicator/equipment/${idCertificate}/certificate/`;

        return this.http.get(url, { responseType: 'blob' });
    }

    downloadPhotoToEssay(idExecution: number, idEssay: number, idAttachment: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/execution/${idExecution}/essay/${idEssay}/photo/${idAttachment}`;
        return this.http.get(url, { responseType: 'blob' });
    }

    downloadAttachmentToSensor(idSensor: number, idAttachment: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/sensor/${idSensor}/file/${idAttachment}`;
        return this.http.get(url, { responseType: 'blob' });
    }

    deleteAttachmentToSensor(idSensor: number, idAttachment: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/sensor/${idSensor}/file/${idAttachment}`;
        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAttachmentInfoToEssay(idExecution: number, idEssay: number, idAttachment: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/essay/${idExecution}/essay/${idEssay}/info/${idAttachment}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAttachmentInfoToSensor(idSensor: number, idAttachment: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/sensor/${idSensor}/info/${idAttachment}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadLogoToGroup(idGroup: number, file: File): Observable<any> {
        const url = environment.coreUrl + `/attachment/group/${idGroup}/logo/`;
        const form = new FormData();

        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadLogoToGroup(group: Group): Observable<any> {
        const url = environment.coreUrl + `/attachment/group/${group.id}/logo/${group.idLogo}`;
        return this.http.get(url, { responseType: 'blob' });
    }

    uploadLogoToClient(idClient: number, file: File): Observable<any> {
        const url = environment.thermalUrl + `/attachment/client/${idClient}/logo/`;
        const form = new FormData();

        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadLogoToClient(client: Client): Observable<any> {
        const url = environment.thermalUrl + `/attachment/client/${client.id}/logo/${client.idLogo}`;
        return this.http.get(url, { responseType: 'blob' });
    }

    getAllTypesForProtocol(): Observable<any> {
        const url = environment.coreUrl + '/attachment/types/protocol/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAllTypesForExecution(): Observable<any> {
        const url = environment.coreUrl + '/attachment/types/execution/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    updateAttachmentToExecution(idExecution: number, idAttachment: number, idType: number, name: string): Observable<any> {
        const url = environment.thermalUrl + `/attachment/execution/attachment/${idAttachment}`;

        const body = {
            idParent: idExecution,
            idAttachment, idType, name
        };

        return this.http.post(url, body).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    updateAttachmentToProtocol(idProtocol: number, idAttachment: number, idType: number, name: string): Observable<any> {
        const url = environment.thermalUrl + `/attachment/protocol/attachment/${idAttachment}`;

        const body = {
            idParent: idProtocol,
            idAttachment, idType, name
        };

        return this.http.post(url, body).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadPhotoEssayProtocol(idProtocol: number, idAttachment: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/protocol/${idProtocol}/image/${idAttachment}/`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    rotatePhotoEssayProtocolImageLeft(idProtocol: number, idAttachment: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/protocol/${idProtocol}/image/${idAttachment}/rotate/left`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    rotatePhotoEssayProtocolImageRight(idProtocol: number, idAttachment: number): Observable<any> {
        const url = environment.thermalUrl + `/attachment/protocol/${idProtocol}/image/${idAttachment}/rotate/right`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

}
